//# packages
// eslint-disable-next-line
import React, { useState, FormEvent, useEffect } from "react";
import Button from "../../components/Button";

//# react hooks
import { useAuth } from "../../hooks/AuthContext";
import { useToast } from "../../hooks/ToastContext";
import { cpfMask } from "../../utils";
import { Link } from "react-router-dom";

//# Images
import Logo from "../../assets/logo_las.svg";

//# components
import { Container, Row, Col, Spinner } from "reactstrap";
import { ContainerBg, InputLogin, ContainerLogin } from "./styles";

const Login: React.FC = () => {
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      if (cpf === "" || password === "") {
        addToast({
          type: "error",
          title: "Campos obrigatórios",
          description: "Os campos CPF e senha são obrigatórios",
        });
      } else {
        setIsSubmitting(true);
        await signIn({
          cpf,
          password,
        });

        setIsSubmitting(false);

        addToast({
          type: "success",
          title: "Seja bem-vindo(a)!",
          // description: 'Ocorreu um erro ao fazer o login, verifique as credenciais.'
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      addToast({
        type: "error",
        title: "Erro na autenticação",
        description:
          "Ocorreu um erro ao fazer o login, verifique as credenciais.",
      });
    }
  }

  return (
    <ContainerBg>
      <Container>
        <Row className="px-4 md-px-5">
          <Col md={6}>
            <div className="container-text">
              <img src={Logo} alt="" />

              <div className="title">
                <h1>Faça seu login na plataforma</h1>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <ContainerLogin>
              <form onSubmit={handleSubmit}>
                <InputLogin
                  type="text"
                  name="cpf"
                  placeholder="Digite seu CPF"
                  value={cpf}
                  onChange={(e) => {
                    setCpf(cpfMask(e.target.value));
                    setError(false);
                  }}
                />

                <InputLogin
                  type="password"
                  name="password"
                  placeholder="Digite sua senha"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(false);
                  }}
                />
                <Link to="/reset-password">
                  <p>Esqueci minha senha</p>
                </Link>
                <Button type="submit">
                  {isSubmitting ? <Spinner size="sm" /> : "Entrar"}
                </Button>
              </form>
            </ContainerLogin>
          </Col>
        </Row>
      </Container>
    </ContainerBg>
  );
};

export default Login;
