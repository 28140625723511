import styled from "styled-components";
import { InputHTMLAttributes } from "react";

import bg from "../../images/bg.svg";

import Button from "../../components/Button";
import Input from "../../components/Input";
import {
  BlueMain,
  BlackMain,
} from "../../styles/colors";

export const ContainerBg = styled.div`
  min-height: 100vh;
  background: url(${bg}) no-repeat;
  background-size: cover;
  background-color: rgb(18, 18, 20);

  display: flex;
  justify-content: center;
  align-items: center;

  .container-text {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;

    img {
      width: 20%;
    }

    .title {
      color: rgb(225, 225, 230);
      margin-top: 25px;

      h1 {
        font-size: 40px;
        line-height: 55px;
        font-weight: 600;

        @media (max-width: 996px) {
          margin-top: 30px;
          font-size: 40px;
          line-height: 60px;
        }
      }
    }
  }
`;

export const ContainerReset = styled.div`
  padding: 25px;
  background-color: ${BlackMain};
  border-radius: 5px;

  p {
    color: ${BlueMain};
    font-weight: 700;
    margin-top: 10px;
    font-size: 13px;
    cursor: pointer;
  }

  button {
    width: 100%;
  }

  @media (max-width: 996px) {
    padding: 35px;
    margin-top: 20px;
  }

  h2 {
    padding-bottom: 10px;
  }

`;

export const Container = styled.div`
  padding-top: 85px;
  --width: 600px;

  .footer {
    padding: 40px 0px;
  }
`;

export const Content = styled.div`
  --max-width: 80%;
  margin: 0 auto;
  background: ${BlackMain};
  margin-top: 40px;
  border-radius: 10px;
  padding: 32px;
  padding-bottom: 5px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  h2 {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 20px;
  }

  select {
    width: 400px;
    height: 60px;
    background: ${BlueMain};
    border-radius: 5px;
    margin: 8px 0;
    font-size: 12px;
    color: #696c73;
    padding: 20px;
  }

`;

export const InputText = styled(Input)<InputHTMLAttributes<HTMLInputElement>>`
  width: 400px;
  opacity: ${(props) => (props.disabled ? "0.8" : "1")};
  margin-bottom: 10px;
`;

export const ButtonSubmit = styled(Button)`
  width: 400px;
  margin-top: 20px;
`;
