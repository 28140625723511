import styled from "styled-components";

interface IProps {
  fill?: string;
  bg?: string;
}

export const Container = styled.div``;

export const Backdrop = styled.div<IProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, ${(props) => props.fill});
  z-index: 500;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const Content = styled.div<IProps>`
  z-index: 100;
  background: ${(props) => props.bg};
  position: relative;
  margin: auto;
  border-radius: 5px;
  padding: 15px;
`;
