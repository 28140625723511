import styled from "styled-components";

//import MultiSelect from 'react-multi-select-component';

import Button from "../../components/Button";
import Input from "../../components/Input";

import {
  BlackMain,
  BlackSecondary,
  BlueMain,
  GreenMain,
  RedMain,
} from "../../styles/colors";

import { RiSlideshowLine } from "react-icons/ri";

interface DepartmentSelectProps {
  onClick?: any;
  show?: boolean;
  cursorInitial?: boolean;
  disabled?: boolean;
}

interface DepartmentOptionsProps {
  show: boolean;
}

interface ContainerAddModuleProps {
  padding: boolean;
}

export const Container = styled.div`
  height: 100vh;
  padding-top: 85px;

  #last-module {
    padding: 40px 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 80%;
  margin: 50px auto 0px;
  background-color: ${BlackMain};
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 32px;

  .button-saved {
    margin-top: 40px;
    margin-left: auto;
    width: 200px;
    display: block;
    padding: 0px;

    a {
      height: 100%;
      width: 100%;
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ContentCourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    margin: 10px 0px;
  }

  select {
    width: 400px;
    height: 60px;
    background: #c4c4c4;
    border-radius: 5px;
    font-size: 12px;
    color: #696c73;
    padding: 20px;
  }

  .hidden {
    visibility: hidden;
    display: none;
  }
`;

export const WrapperCourseInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperTextArea = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputImage = styled(Input)`
  visibility: hidden;
`;

export const InputTitle = styled(Input)`
  width: 100%;
`;

export const ButtonSaveCourse = styled(Button)`
  align-self: flex-end;
  width: 210px;
  right: -10px;
`;

export const LabelImage = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 400px;
  border: 2px dashed ${BlackSecondary};
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  min-height: 250px;
  height: 250px;
  transition: border 0.2s ease 0s;
  overflow: hidden;

  margin-bottom: 20px;

  &:hover {
    border: 2px dashed ${GreenMain};
  }

  .hidden {
    display: none;
  }
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageUpload = styled.img``;

export const InfoImageUpload = styled.p`
  font-size: 13px;
  margin-top: 12px;
  color: #ffffff;
`;

export const TextArea = styled.textarea`
  background: ${BlackSecondary};
  border-radius: 5px;
  font-size: 14px;
  padding: 20px;
  width: 100%;
  resize: none;
  color: #fff;
`;

export const Module = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;

  margin: 20px auto;
  // background-color: #191c22;
  border-radius: 5px;
`;

export const ContentAddModule = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InputTitleModule = styled(Input)`
  width: 800px;
`;

export const ButtonAddModule = styled(Button)`
  padding: 10px 40px;
`;

export const GroupControl = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContentLessonsAddModule = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ColumnsLessonAdd = styled.div``;

export const InputAddLesson = styled(Input)`
  width: 350px;
`;

export const ButtonRemoveModule = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 20px;
  cursor: pointer;
`;

export const ButtonRemoveLesson = styled.button`
  align-self: flex-end;
  width: 46px;
  height: 46px;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  background: transparent;
  margin: 0;
  border: 1px solid ${RedMain};
  transition: background 0.2s ease-in-out;
  &:hover {
    background: ${RedMain};
  }
`;

export const ButtonEditQuizz = styled.button`
  width: 46px;
  height: 46px;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  background: transparent;
  margin: 0;
  border: 1px solid ${BlueMain};
  transition: background 0.2s ease-in-out;
  margin-right: 15px;
  &:hover {
    background: ${BlueMain};
  }
`;

export const IconRemove = styled.img`
  width: 60%;
`;

export const ListLessonsModule = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  background: ${BlackSecondary};
  border-radius: 5px;
  padding: 15px;
  transition: ease all 0.3s;
  margin-bottom: 15px;
`;

export const NameAndLink = styled.div`
  font-size: 14px;
  flex-direction: column;
  align-items: baseline;

  a {
    display: block;
    color: ${BlueMain};

    border-radius: 5px;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin-top: 8px;
  }

  p {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
  }
`;

export const Modules = styled.div`
  max-width: 80%;

  margin: 20px auto;
  padding: 32px;
  margin-top: 40px;
  border-radius: 5px;
  background-color: ${BlackMain};

  .ContainerAddLesson {
    input {
      width: 100%;
    }
  }
`;

export const ContentButtonsModule = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  div {
    cursor: pointer;
  }
  button {
    background: none;
    border-radius: 32px;
    padding: 5px 20px;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background: white;
      color: #333;
    }
  }
`;

export const ButtonPlusModule = styled.button`
  background-color: transparent;
  border: none !important;
`;

export const IconPlus = styled.img``;

export const ContentModuleLessons = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleQuiz = styled.h1`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const TextInput = styled(Input)`
  width: 100%;
  margin-bottom: 10px;
`;

export const ContentModal = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */

  /* background: red; */
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonModal = styled(Button)`
  margin-left: 15px;
  width: 100px;
`;

export const TitleButtonModal = styled.p``;

/*export const Select = styled(MultiSelect)`
  width: 400px;
  height: 60px;
  background: #c4c4c4;
  border-radius: 5px;
  font-size: 12px;
  color: #696c73;
  padding: 20px;

  .dropdown-container {
    position: relative;
    outline: 0;
    background: #c4c4c4;
    border: none;
    border-radius: var(--rmsc-radius);
  }

  .gray {
    color: #383838;
  }
`;*/

export const IconSlide = styled(RiSlideshowLine)`
  margin-right: 10px;
`;

export const ContainerQuestion = styled.div`
  padding: 10px;

  background: #84848494;
  border-radius: 5px;
  width: 100%;
  position: relative;

  .exclueItem {
    position: absolute;
    right: 10px;
    color: rgb(255 0 0);
    cursor: pointer;
  }

  .title {
    font-size: 20px;
  }
`;

// ---------------------------------------------------------

export const ContainerAddModule = styled.div<ContainerAddModuleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BlackMain};
  width: 80%;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: ${(props) => props.padding && "32px"};

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .input-module {
    width: 100%;
    input {
      width: 100%;
    }
  }

  .submit-module {
    width: 200px;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 0px;
    button {
      width: 100%;
      padding: 0px;
      a {
        width: 100%;
        height: 100%;
        padding: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const ContainerHeaderModule = styled.div`
  align-items: flex-end;
  small {
    font-size: 12px;
    font-weight: 600;
  }

  h1 {
    font-size: 30px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .containerHeaderButton {
    display: flex;
    justify-content: flex-end;

    button-align {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .submitlesson {
    display: flex !important;
    align-items: center;
    width: 100%;
    div {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`;

export const HeaderModule = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  h1 {
    color: ${GreenMain};
    font-size: 22px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 25rem;
    overflow: hidden;
  }

  button {
    width: 200px;
    padding: 15px 0px;
  }
`;

export const GroupDataModule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    width: 100%;
    #fileLesson {
      display: none;
    }

    label {
      width: 100%;
      padding: 15px 20px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed transparent;
      background: ${BlackSecondary};
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      transition: border 0.2s ease 0s;
      &:hover {
        border: 2px dashed ${GreenMain};
      }
      svg {
        margin-right: 10px;
      }
    }

    .fileName {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${BlackSecondary};
      width: 100%;
      text-align: center;
      padding: 15px;
      border-radius: 5px;
      margin-top: 15px;
      min-height: 60px;
      svg {
        margin-right: 10px;
      }
    }
  }
  input {
    width: 100%;
  }

  .btn-add-video {
    margin-left: auto;
    width: 200px;
    display: block;
    margin-top: 40px;
    margin-bottom: 0px;
  }
`;

export const FileLesson = styled.div`
  margin-top: 50px;

  .fileName {
    a {
      color: white;
    }
  }
  h1 {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  p {
    line-height: 22px;
    svg {
      margin-right: 10px;
    }
  }
`;

export const ButtonRemoveFileLesson = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  background: transparent;
  margin: 0;
  border: 1px solid ${RedMain};
  transition: background 0.2s ease-in-out;
  margin-left: auto;

  svg {
    margin-right: 0px !important;
  }
  &:hover {
    background: ${RedMain};
  }
`;

export const ButtonEditLesson = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  background: transparent;
  margin: 0 10px 0 0;
  border: 1px solid ${BlueMain};
  transition: background 0.2s ease-in-out;
  margin-left: auto;

  svg {
    margin-right: 0px !important;
  }
  &:hover {
    background: ${BlueMain};
  }
`;

export const GroupButtonLesson = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ListLesson = styled.div`
  margin-top: 50px;

  h1 {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  p {
    line-height: 22px;
    svg {
      margin-right: 10px;
    }
  }
`;

export const DepartmentSelect = styled.div<DepartmentSelectProps>`
  width: 100%;
  height: max-content;
  background: ${BlackSecondary};
  border-radius: 5px;
  font-size: 12px;
  color: #696c73;
  position: relative;
  opacity: ${(props) => (props.disabled ? "0.8" : "1")};

  &:focus {
    border: 2px solid ${BlueMain};
  }
`;

export const DepartmentOptions = styled.div<DepartmentOptionsProps>`
  position: absolute;
  background: ${BlackSecondary};
  transform: translateY(-5px);
  z-index: 999;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  overflow-y: auto;
  transition: 0.2s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: ${(props) => (props.show ? "180px" : "0px")};
  span:first-child {
    height: 50px;
  }
  span:last-child {
    height: 50px;
  }
  span {
    padding: 1rem;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;
export const BadgeDepartment = styled.div`
  min-width: 100px;
  max-width: max-content;
  background: ${BlueMain};
  border-radius: 32px;
  padding: 5px 30px 5px 15px;
  color: white;
  display: flex;
  position: relative;

  span {
    width: 12px;
    height: 2px;
    background: white;
    border-radius: 5px;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    &:hover {
      background: #fa8072;
      &::before {
        background: #fa8072;
      }
    }

    &::before {
      content: "";
      background: white;
      width: 12px;
      height: 2px;
      display: block;
      border-radius: 5px;
      transform: rotate(-90deg);
    }
  }
`;

export const DepartmentSelectButton = styled.div<DepartmentSelectProps>`
  width: 100%;
  min-height: 60px;
  max-height: max-content;
  padding: 20px;
  cursor: ${(props) => (props.cursorInitial ? "initial" : "pointer")};
  gap: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .arrow-down {
    position: absolute;
    right: 5%;
    bottom: 35%;
    transition: 0.2s ease-out;
    transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

export const GroupCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15;
  label {
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const GroupTitleCourse = styled.div`
  /* margin-bottom: 15px; */
`;
