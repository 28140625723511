import styled from "styled-components";
import { InputHTMLAttributes } from "react";

import Button from "../../components/Button";
import Input from "../../components/Input";
import {
  BlueMain,
  BlackSecondary,
  BlackMain,
  RedMain,
} from "../../styles/colors";

interface DepartmentSelectProps {
  onClick?: any;
  show?: boolean;
  cursorInitial?: boolean;
  disabled?: boolean;
}

interface DepartmentOptionsProps {
  show: boolean;
}

export const DepartmentSelect = styled.div<DepartmentSelectProps>`
  width: 400px;
  height: max-content;
  background: ${BlackSecondary};
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #696c73;
  position: relative;
  opacity: ${(props) => (props.disabled ? "0.8" : "1")};
`;

export const DepartmentOptions = styled.div<DepartmentOptionsProps>`
  position: absolute;
  background: ${BlackSecondary};
  transform: translateY(-5px);
  z-index: 999;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  overflow-y: auto;
  transition: 0.2s ease-in-out;
  height: ${(props) => (props.show ? "180px" : "0px")};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  span {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #fff;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;
export const BadgeDepartment = styled.div`
  min-width: 100px;
  max-width: max-content;
  background: ${BlueMain};
  border-radius: 32px;
  padding: 5px 30px 5px 15px;
  color: white;
  display: flex;
  position: relative;

  span {
    width: 12px;
    height: 2px;
    background: white;
    border-radius: 5px;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    right: 5%;
    top: 45%;
    cursor: pointer;
    &:hover {
      background: ${RedMain};
      &::before {
        background: ${RedMain};
      }
    }

    &::before {
      content: "";
      background: white;
      width: 12px;
      height: 2px;
      display: block;
      border-radius: 5px;
      transform: rotate(-90deg);
    }
  }
`;

export const DepartmentSelectButton = styled.div<DepartmentSelectProps>`
  width: 100%;
  min-height: 60px;
  max-height: max-content;
  padding: 15px 50px 15px 20px;
  cursor: ${(props) => (props.cursorInitial ? "initial" : "pointer")};
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .arrow-down {
    position: absolute;
    right: 5%;
    bottom: 30%;
    transition: 0.2s ease-out;
    color: white;
    transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

export const Container = styled.div`
  padding-top: 85px;

  .footer {
    padding: 40px 0px;
  }
`;

export const Content = styled.div`
  max-width: 80%;
  margin: 0 auto;
  background: ${BlackMain};
  margin-top: 40px;
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  h2 {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 20px;
  }

  select {
    width: 400px;
    height: 60px;
    background: ${BlueMain};
    border-radius: 5px;
    margin: 8px 0;
    font-size: 12px;
    color: #696c73;
    padding: 20px;
  }
`;

export const InputText = styled(Input)<InputHTMLAttributes<HTMLInputElement>>`
  width: 400px;
  opacity: ${(props) => (props.disabled ? "0.8" : "1")};
  margin-bottom: 10px;
`;

export const ButtonSubmit = styled(Button)`
  width: 400px;
  margin-top: 20px;
`;
