import React from "react";

import { Container } from "./styles";

interface LabelProps {
  children: string;
}

export default function Label({ children }: LabelProps) {
  return (
    <Container>
      <h2>{children}</h2>
    </Container>
  );
}
