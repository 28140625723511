import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";

import api from "../../services/api";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
// eslint-disable-next-line
import { Modal, Spinner } from "reactstrap";

import { useToast } from "../../hooks/ToastContext";

import icon_search from "../../images/icon_search.svg";

interface DataDepartament {
  id: number;
  name: string;
  email_responsible: string;
}

interface DataResponsibleUsers {
  id: string;
  name: string;
  email: string;
}

const Departments: React.FC = () => {
  const [dataDepartament, setDataDepartament] = useState<DataDepartament[]>([]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [dataResponsibleUsers, setDataResponsibleUsers] = useState<
    DataResponsibleUsers[]
  >([]);
  // eslint-disable-next-line
  const [userResponsible, setUserResponsible] = useState<string>();
  const [emailUserResponsible, setEmailUserResponsible] = useState<string>();
  // eslint-disable-next-line
  const [idUserResponsible, setIdUserResponsible] = useState<number>();
  const [nameDepartament, setNameDepartament] = useState<string>();

  const { addToast } = useToast();

  useEffect(() => {
    api.get("v1/departament").then((response) => {
      setDataDepartament(response.data.data);
    });
  }, [modalShow]);

  useEffect(() => {
    api.get("v1/get-users-permission/1").then((response) => {
      setDataResponsibleUsers(response.data.users);
    });
  }, []);

  function changeIdAndEmailUserResponsible(user: string) {
    const [id, email] = user.split(",");

    setEmailUserResponsible(email);
    setIdUserResponsible(Number(id));
  }

  function handleSubmit() {
    api
      .post("v1/departament", {
        name: nameDepartament,
        email_responsible: emailUserResponsible,
        id_user_responsible: userResponsible,
      })
      .then((response) => {
        if (response.status === 201) {
          addToast({
            type: "success",
            title: "Departamento cadastrado com sucesso",
          });

          setModalShow(false);
        }
      });
  }

  return (
    <S.Container>
      <Header />
      <S.Content>
        <S.ContentFilters>
          <S.ButtonAddDepartment onClick={() => setModalShow(true)}>
            Cadastrar departamento
          </S.ButtonAddDepartment>
        </S.ContentFilters>

        <S.ContentListHeader>
          <S.ColumnId>
            <h1>ID</h1>
          </S.ColumnId>
          <S.ColumnName>
            <h1>Departamento</h1>
          </S.ColumnName>
          <S.ColumnEmail>
            <h1>E-mail responsável</h1>
          </S.ColumnEmail>
          <S.ColumnActions>
            <h1>Ações</h1>
          </S.ColumnActions>
        </S.ContentListHeader>

        {dataDepartament.map((department) => (
          <S.ContentList key={department.id}>
            <S.ColumnId>
              <h1>{department.id}</h1>
            </S.ColumnId>
            <S.ColumnName>
              <h1>{department.name}</h1>
            </S.ColumnName>
            <S.ColumnEmail>
              <h1>{department.email_responsible}</h1>
            </S.ColumnEmail>
            <S.ColumnActions>
              <Link to="">
                <img src={icon_search} alt="" />
              </Link>
            </S.ColumnActions>
          </S.ContentList>
        ))}
      </S.Content>
      <section className="footer">
        <Footer />
      </section>

      <Modal
        className="create-department"
        isOpen={modalShow}
        toggle={() => setModalShow(!modalShow)}
        centered={true}
      >
        <S.ModalContent>
          {/* <S.TitleModal>Cadastro de Departamento</S.TitleModal> */}
          <S.Form>
            <S.ContentInput>
              <S.Label>Nome do departamento:</S.Label>
              <S.InputText
                type="text"
                placeholder="Ex: Financeiro"
                onChange={(e) => setNameDepartament(e.target.value)}
              />
            </S.ContentInput>

            <S.ContentInput>
              <S.Label>Usuário responsável:</S.Label>
              <S.Select
                onChange={(e) =>
                  changeIdAndEmailUserResponsible(e.target.value)
                }
              >
                <option value={Number(0)} disabled selected>
                  Selecione
                </option>
                {dataResponsibleUsers.map((userResponsible) => (
                  <option
                    key={userResponsible.id}
                    value={[userResponsible.id, userResponsible.email]}
                  >
                    {userResponsible.name}
                  </option>
                ))}
              </S.Select>
            </S.ContentInput>

            <S.ContentInput>
              <S.Label>E-mail do responsável:</S.Label>
              <S.InputText
                type="text"
                placeholder="Selecione o responsável no campo acima"
                value={emailUserResponsible}
                disabled
              />
            </S.ContentInput>
          </S.Form>

          <S.ContentButtons>
            <S.ButtonClose onClick={() => setModalShow(false)}>
              Fechar
            </S.ButtonClose>
            <S.ButtonRegister onClick={handleSubmit}>
              Cadastrar
            </S.ButtonRegister>
          </S.ContentButtons>
        </S.ModalContent>
      </Modal>
    </S.Container>
  );
};

export default Departments;
