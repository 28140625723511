import styled from "styled-components";
import { BlackSecondary } from "../../styles/colors";

export const Container = styled.div``;

export const InputField = styled.input`
  height: 60px;
  background: ${BlackSecondary};
  border-radius: 5px;
  padding: 20px;
  font-size: 12px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #696c73;
  }
  :-ms-input-placeholder {
    color: #696c73;
  }
`;
