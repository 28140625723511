// eslint-disable-next-line
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";

import { useAuth } from "../../hooks/AuthContext";
import { useToast } from "../../hooks/ToastContext";

import api from "../../services/api";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { HiOutlineTrash, HiOutlineCog } from "react-icons/hi";
import { IconColor } from "../../styles/colors";

import ModalExclue from "../../components/ModalExclueCourse";

interface CourseProps {
  id: string;
  name: string;
  description: string;
  image: string;
}

export default function Courses() {
  const [courses, setCourses] = useState<CourseProps[]>([]);
  const { user } = useAuth();
  const { addToast } = useToast();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [idCourse, setIdCourse] = useState(0);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const handleGetData = useCallback(() => {
    user.id_permission === 2 &&
      api
        .get(`v1/get-courses-user/${user.id}`)
        .then(({ data }) => setCourses(data.courses));

    user.id_permission === 1 &&
      api.get("v1/course").then(({ data }) => setCourses(data.data));
  }, [user.id, user.id_permission])

  useEffect(() => {
    
    handleGetData();
  }, [handleGetData]);


  const handleDemoveCourse = async (id: number) => {
    setIsLoadingRemove(true);
    try {
      await api.delete(`v1/course/${id}`);
      handleGetData();
      addToast({
        type: "success",
        title: "Curso removido com sucesso",
      });
      setIsLoadingRemove(false);
      toggle();
    } catch (error) {
      setIsLoadingRemove(false);
      addToast({
        type: "error",
        title: "Erro ao excluir curso",
        description: "Ocorreu um erro ao remover o curso, tente novamente.",
      });
    }
  };

  return (
    <S.Container>
      <Header />
      <S.Content>
        {user.id_permission === 1 && (
          <S.ContentButton>
            <Link to="/add-course">
              <S.ButtonAddCourse>Novo curso</S.ButtonAddCourse>
            </Link>
          </S.ContentButton>
        )}

        <S.CardContainer>
          {courses.map((course) => (
            <S.Card key={course.id}>
              <Link to={`/course/${String(course.id)}`}>
                <S.WrapperImage>
                  <S.Image
                    //src={`https://userbox.com.br/ead_api/storage/app/public/courses/${course.image}`}
                    src={`https://las.app.br/ead_api/storage/app/public/courses/${course.image}`}
                  />

                  <S.TitleCourse>{course.name}</S.TitleCourse>
                </S.WrapperImage>

                <S.ContentText>
                  <S.DescriptionCourse>
                    {course.description}
                  </S.DescriptionCourse>
                </S.ContentText>
              </Link>

              {user.id_permission === 1 && (
                <S.CardButtons>
                  <Link to={`/edit-course/${String(course.id)}`}>
                    <HiOutlineCog size={22} color={IconColor} />
                  </Link>

                  <S.ButtonDelete
                    onClick={async () => {
                      setIdCourse(parseInt(course.id));
                      toggle();
                    }}
                  >
                    <HiOutlineTrash size={22} color={IconColor} />
                  </S.ButtonDelete>
                </S.CardButtons>
              )}
            </S.Card>
          ))}
        </S.CardContainer>
      </S.Content>
      <section className="footer">
        <Footer />
      </section>

      <ModalExclue
        toggle={toggle}
        modal={modal}
        handleDemoveCourse={handleDemoveCourse}
        id={idCourse}
        isLoadingRemove={isLoadingRemove}
      />
    </S.Container>
  );
}
