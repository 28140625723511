import styled from "styled-components";

import Button from "../../components/Button";
import { BlackMain, GreyLight, BlueMain } from "../../styles/colors";

export const Container = styled.div`
  height: 100vh;
  padding-top: 105px;

  .footer {
    width: 100%;
    padding: 40px 0px;
  }
`;

export const Content = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
`;

export const ButtonAddCourse = styled(Button)`
  width: 190px;
  box-shadow: rgb(0 0 0 / 60%) 0px 5px 20px;
  font-weight: 700;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  margin-top: 30px;
  /* background: green; */
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 480px;
  padding: 32px;
  border-radius: 5px;
  background: ${BlackMain};
  border: 2px solid ${BlackMain};
  transition: border 0.2s ease 0s, transform 0.2s ease 0s;

  &:hover {
    border: 2px solid ${BlueMain};
    transform: translateY(-7px);
  }

  a {
    text-decoration: none;
  }
`;

export const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 20px;
  object-fit: cover;
`;

export const CardButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  width: 100%;

  a {
    width: 46px;
    height: 46px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s ease 0s;
    &:hover {
      background: #141316;
    }
  }
`;

export const ButtonEdit = styled.img`
  margin-right: 20px;
`;

export const ButtonDelete = styled.button`
  background-color: transparent;
  border: none;
  transition: background 0.2s ease 0s;
  width: 46px;
  height: 46px;
  border-radius: 5px;

  &:hover {
    background: #141316;
  }
`;

export const ContentText = styled.div`
  min-height: 105px;
`;

export const TitleCourse = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: white;
  margin-top: 30px;
  /* text-transform: uppercase; */
`;

export const DescriptionCourse = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${GreyLight};
`;

export const WatchCourse = styled.button`
  background-color: transparent;
  color: #fff;
  font-size: 19px;
  border: none;
`;
