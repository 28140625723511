import styled from "styled-components";
import {
  BlackMain,
  BlackSecondary,
  GreenMain,
  BlueMain,
  RedMain,
} from "../../styles/colors";

interface ButtonProps {
  primaryColor?: boolean;
}

interface GroupInputProps {
  description?: boolean;
}

export const PictureSlide = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

export const ModalSlide = styled.div`
  .modalslide {
    transform: translateY(-10%);
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  max-height: 650px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: ${BlackMain};
  border-radius: 15px;
  padding: 32px;

  h1 {
    font-size: 22px;
    color: white;
    font-weight: 700;
  }

  h3 {
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin: 1rem 0 2rem 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
`;

export const GroupClose = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #808080;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 40px;

  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 0.6;
  }

  span {
    width: 20px;
    height: 3px;
    background: white;
    border-radius: 5px;
    display: block;
    transform: rotate(45deg);

    &::before {
      content: "";
      background: white;
      width: 20px;
      height: 3px;
      display: block;
      border-radius: 5px;
      transform: rotate(-90deg);
    }
  }
`;

export const WrapperSlide = styled.div`
  /* padding: 2rem; */
  margin-bottom: 2rem;

  .slick-dots {
    .slick-active {
      button {
        &::before {
          color: #fff !important;
        }
      }
    }
    button {
      &::before {
        font-size: 8px;
        color: grey !important;
      }
    }
  }
`;

export const Button = styled.label<ButtonProps>`
  background: ${BlueMain};
  padding: 15px 0px;
  width: max-content;
  border-radius: 5px;
  width: 200px;
  margin-top: 40px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  transition: background-color 0.2s;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background: "#896ceb";
  }
`;

export const NoImagePreview = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  height: 250px;
  border-radius: 5px;
  transition: border 0.2s ease 0s;
  border: 2px dashed ${BlackSecondary};
  cursor: pointer;

  &:hover {
    border: 2px dashed ${GreenMain};
  }

  p {
    margin-top: 1.5rem;
  }

  .hidden {
    display: none;
  }
`;

export const GroupControlSlide = styled.div`
  display: flex;
  width: max-content;
  gap: 15px;
  margin-left: auto;
  margin-top: auto;
`;

export const FormSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const GroupInput = styled.div<GroupInputProps>`
  display: flex;
  flex-direction: column;
  input {
    margin-top: 15px;
    width: 100%;
  }
`;

export const ButtonDeleteSlide = styled.div`
  padding: 8px 20px;
  color: white;
  background: ${RedMain};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  min-width: max-content;
  border-radius: 5px;
  width: 200px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  position: absolute;

  right: 20px;
  bottom: 10px;

  &:hover {
    background: #e55050;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  img {
    width: 15px;
    height: 15px;
  }
`;

export const WrapperImage = styled.label`
  position: relative;
  padding: 1px;
  width: 100%;
  height: 100%;
  height: 250px;
  border-radius: 5px;
  transition: border 0.2s ease 0s;
  border: 2px dashed ${BlackSecondary};
  cursor: pointer;

  &:hover {
    border: 2px dashed ${GreenMain};
  }

  p {
    margin-top: 1.5rem;
  }

  .hidden {
    display: none;
  }
`;
