// eslint-disable-next-line
import React, { ChangeEvent, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "./styles";
import Upload from "../../assets/upload.svg";
// eslint-disable-next-line
import IconDelete from "../../assets/delete.svg";
import api from "../../services/api";
import { useToast } from "../../hooks/ToastContext";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { uuid } from "uuidv4";
import { HiOutlineTrash } from "react-icons/hi";
import { RedMain } from "../../styles/colors";
import { Spinner } from "reactstrap";

import { Modal } from "reactstrap";

interface ModalSlideProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  module: any;
  lessonName: any;
  savedSlides: () => void;
}

interface SlideDataProps {
  title: string;
  description: string;
}

interface ImageSlideProps {
  image: File;
  id: string;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ModalSlide = ({
  modalIsOpen,
  closeModal,
  lessonName,
  savedSlides,
  module,
}: ModalSlideProps) => {
  const [imagesSlide, setImagesSlide] = useState<ImageSlideProps | any>([]);
  const [slideData, setSlideData] = useState<SlideDataProps | any>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { addToast } = useToast();

  useEffect(() => {
    setImagesSlide([]);
    setSlideData({
      title: "",
      description: "",
    });
  }, [modalIsOpen]);

  const handleSelectImage = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) return;

    const currentImagesSlide = imagesSlide ? [...imagesSlide] : [];
    currentImagesSlide.push({ image: event.target.files[0], id: uuid() });

    setImagesSlide(currentImagesSlide);
  };

  const handleSave = async () => {
    const data = new FormData();
    setIsSubmitting(true);

    if (!slideData.title) {
      setIsSubmitting(false);
      return addToast({
        type: "error",
        title: "Erro ao criar slide",
        description: "titulo do slide é obrigatório",
      });
    }

    imagesSlide.map((file: ImageSlideProps) =>
      data.append("filenames[]", Object(file.image))
    );
    data.append("name", lessonName);
    data.append("id_module", module.id);
    data.append("title", slideData?.title);
    data.append("description", slideData?.description);

    await api
      .post("v1/slide", data)
      .then(() => {
        setIsSubmitting(false);
        closeModal();
        savedSlides();

        addToast({
          type: "success",
          title: "Slide criado com sucesso",
          description: `O curso slide foi criado com sucesso`,
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
      });
  };

  const handleDeleteSlide = (idImage: string) => {
    const currentImagesSlide = [...imagesSlide];
    const indexSlide = imagesSlide.findIndex(
      (slide: ImageSlideProps) => slide.id === idImage
    );

    currentImagesSlide.splice(indexSlide, 1);

    setImagesSlide(currentImagesSlide);
  };

  return (
    <S.ModalSlide>
      <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        size="xl"
        centered={true}
        className="modalslide"
        // fade={true}
      >
        <S.ModalContent>
          <S.WrapperSlide>
            <Slider {...settings}>
              {imagesSlide &&
                imagesSlide.map((file: ImageSlideProps) => (
                  <>
                    {file && (
                      <S.WrapperImage key={file.id} htmlFor="slideimage">
                        <S.PictureSlide
                          src={URL.createObjectURL(file.image)}
                          alt={file.image?.name}
                        />

                        <input
                          type="file"
                          className="hidden"
                          accept="image/*"
                          id="slideimage"
                          onChange={handleSelectImage}
                        />

                        <S.ButtonDeleteSlide
                          onClick={() => handleDeleteSlide(file.id)}
                        >
                          {file.image?.name}
                          <span>
                            <HiOutlineTrash color={RedMain} size={20} />
                          </span>
                        </S.ButtonDeleteSlide>
                      </S.WrapperImage>
                    )}
                  </>
                ))}
            </Slider>

            {imagesSlide && imagesSlide.length <= 0 && (
              <S.NoImagePreview htmlFor="slideimage">
                <img src={Upload} alt="upload" />
                <p>Clique no botão abaixo para carregar a imagem</p>

                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  id="slideimage"
                  onChange={handleSelectImage}
                />
              </S.NoImagePreview>
            )}
          </S.WrapperSlide>

          <S.FormSlide>
            <S.GroupInput>
              <Label>Título:</Label>
              <Input
                type="text"
                placeholder="Digite o título do slide"
                value={slideData?.title}
                onChange={(e) =>
                  setSlideData({ ...slideData, title: e.target.value })
                }
              />
            </S.GroupInput>

            <S.GroupInput description>
              <Label>Descrição:</Label>
              <Input
                type="text"
                placeholder="Digite a descrição do slide"
                value={slideData?.description}
                onChange={(e) =>
                  setSlideData({ ...slideData, description: e.target.value })
                }
              />
            </S.GroupInput>
          </S.FormSlide>

          <S.GroupControlSlide>
            {imagesSlide && imagesSlide.length >= 1 && (
              <S.Button onClick={() => handleSave()}>
                {isSubmitting ? <Spinner size="sm" /> : "Salvar"}
              </S.Button>
            )}
          </S.GroupControlSlide>
        </S.ModalContent>
      </Modal>
    </S.ModalSlide>
  );
};

export default ModalSlide;
