import React from "react";

import { Switch } from "react-router-dom";

import Route from "./Route";

import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import ChangePassword from "../pages/ChangePassword";
import Dashboard from "../pages/Dashboard";
import RegisterUser from "../pages/RegisterUser";
import Users from "../pages/Users";
import Courses from "../pages/Courses";
import Course from "../pages/Course";
import CourseForm from "../pages/AddCourse";
//import EditCourse from "../pages/EditCourse";
import Profile from "../pages/Profile";
import Departments from "../pages/Departments";
import Help from "../pages/Help";

// import Test from '../pages/Test';

import Teste from "../pages/Teste";

const Routes: React.FC = () => {
  return (
    // <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/reset-password" exact component={ResetPassword}/>
      <Route path="/change-password" exact component={ChangePassword}/>
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/register-user" component={RegisterUser} isPrivate />
      <Route path="/departments" component={Departments} isPrivate />
      <Route path="/users" component={Users} isPrivate />
      <Route path="/courses" component={Courses} isPrivate />
      <Route path="/course/:id" component={Course} isPrivate />
      <Route path="/add-course" component={CourseForm} isPrivate />
      <Route path="/edit-course/:id" component={CourseForm} isPrivate />
      <Route path="/profile/:id" component={Profile} isPrivate />
      <Route path="/help" component={Help} isPrivate />
      <Route path="/teste" component={Teste} isPrivate />
    </Switch>
    // </BrowserRouter>
  );
};

export default Routes;
