import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { format, compareAsc } from "date-fns";
import * as S from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { UncontrolledCollapse, Collapse } from "reactstrap";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { FiCheckCircle } from "react-icons/fi";
import { HiChevronDown, HiOutlineDownload } from "react-icons/hi";
import api from "../../services/api";

import { useToast } from "../../hooks/ToastContext";
import { useAuth } from "../../hooks/AuthContext";
import gerarCertificadoPDF from "../../hooks/gerarCertificado";
import ModalCourseSlide from "../../components/ModalCourseSlide";
import ModalQuestion from "../../components/ModalQuestion";
import { cpfMask } from "../../utils";
import { Spinner } from "reactstrap";
// eslint-disable-next-line
import { number } from "yup";

interface ParamsProps {
  id: string;
}

interface LessonProps {
  id: number;
  name: string;
  video_url: string;
  lessonWatched: boolean;
  file: string;
  description: string;
  file_type: string;
}

interface QuestionsProps {
  id: number;
  id_quiz: number;
  title: string;
  description: string;
  option_1: string;
  option_2: string;
  option_3: string;
  option_4: string;
  option_5: string;
  done: boolean;
}

interface QuizProps {
  id: number;
  id_module: number;
  questions?: Array<QuestionsProps>;
  title: string;
}

interface SlidesProps {
  id: number;
  id_slide: number;
  file: string;
}

interface CourseSlideProps {
  id: number;
  id_module: number;
  slides: Array<SlidesProps>;
  title: string;
}

interface ModulesProps {
  id: number;
  name: string;
  done: boolean;
  first_module?: boolean;
  release_date: string;
  lessons?: Array<LessonProps>;
  quizzes?: Array<QuizProps>;
  courseslide?: CourseSlideProps[];
}

interface CourseProps {
  id: number;
  total_hours: number;
  date_of_conclusion: Date;
  name: string;
  description: string;
  modules?: Array<ModulesProps>;
  image: string;
}

interface Quizz {
  title: String;
  questions: Array<QuestionsProps>;
}

const Course = () => {
  const [urlVideoLesson, setUrlVideoLesson] = useState<string>();
  const [idLesson, setIdLesson] = useState<number>();
  const [course, setCourse] = useState<CourseProps>({} as CourseProps);
  const [courseModules, setCourseModules] = useState<ModulesProps[]>([]);
  const [availableModules, setAvailableModules] = useState<ModulesProps[]>([]);

  const [questions, setQuestions] = useState<QuestionsProps>(
    {} as QuestionsProps
  );
  // eslint-disable-next-line
  const [showVideo, setShowVideo] = useState<boolean>(true);
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [answer, setAnswer] = React.useState<string>();
  const [answerAndLesson, setAnswerAndLesson] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [courseDone, setCourseDone] = useState<boolean>(false);
  const [showModalSlide, setShowModalSlide] = useState<boolean>(false);
  const [lessonSlides, setLessonSlides] = useState<SlidesProps[]>([]);
  const [lessonShow, setLessonShow] = useState<LessonProps>({} as LessonProps);

  const { id } = useParams<ParamsProps>();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const handleOpenModalSlide = (courseslide: CourseSlideProps) => {
    setShowModalSlide(true);
    setLessonSlides(courseslide.slides);
  };

  const [modalQuestion, setModalQuestion] = useState(false);
  const toggleModalQuestion = () => setModalQuestion(!modalQuestion);

  const [currentQuiz, setCurrentQuiz] = useState<Quizz | any>();


  useEffect(() => {
    //console.log(id);
    //console.log(user.id);
    //console.log(course.id);DESSA FORMA trazendo course aqui FICA undefined
    api
      .post("v1/list-courses-complete", {
        id_course: id,
        id_user: user.id,
      })
      .then(({ data }) => {
        const courseModules: ModulesProps[] = data.courses[0].modules;
        //let courseModulesInfo: ModulesProps[] = data.courses[0].lessons;
        //console.log("DATA TOTAL HOURS:::",Math.trunc(course.total_hours));
        //const cargaHorariaCurso = Math.trunc(course.total_hours);
        
        const firstModule = courseModules[0];
        //console.log("courseModules:",courseModules[0].done);
        if (firstModule) {
          firstModule.first_module = true;
        }

        var dataConclusao = data.courses[0].date_of_conclusion;
        //console.log("DATA DE CONCLUSAO", dataConclusao);
        const modulos_inacabados = courseModules.filter((modulosUnfinished) => modulosUnfinished.done===false);
        if(dataConclusao===null && modulos_inacabados.length===0){
          setCourseDone(true);
          callbackEndCourse();
          //console.log("ESTE CURSO FOI FINALIZADO!");
        }
        if(modulos_inacabados.length===0){
          setCourseDone(true);
          //console.log("CERTIFICADO DISPONÍVEL!");
        }
        else{
          setCourseDone(false);
          //console.log("AINDA RESTAM MODULOS A SEREM CONCLUIDOS");
        }
        //console.log("MODULOS INACABADOS",modulos_inacabados);

        setCourseModules(courseModules);
        setCourse(data.courses[0]);
      });
      // eslint-disable-next-line
  }, [id, user.id, answerAndLesson]);

  

  // Function that returns if the course was finished
  function callbackEndCourse() {
    //console.log("finalizou o curso?");
    api
      .post("v1/finish-course", {
        id_user: user.id,
        id_course: id
      })
      .then((response) => {
        //console.log(response);
        //console.log("RESPONSE API finish-course",response);
        if (response.data.success) {
          addToast({
            type: "success",
            title: "Curso finalizado com sucesso",
          });
        }
      });
  }

  // Function that returns if the video was finished
  function callbackEndVideo() {
    //console.log("finalizou o video?");
    api
      .post("v1/watched-lesson", {
        id_user: user.id,
        id_lesson: idLesson,
        id_course: course.id
      })
      .then((response) => {
        //console.log(response);
        if (response.data.success) {
          setAnswerAndLesson(false);
          setAnswerAndLesson(true);
          addToast({
            type: "success",
            title: "Aula finalizada com sucesso",
          });
        }
      });
  }

  // Function to answer questionnaire
  function handleFormToAnswer(idQuestion: number, idQuiz: number) {
    /*console.log("enviou a questão!");
    console.log(user.id);
    console.log(course.id);
    console.log(idQuiz);
    console.log(idQuestion);
    console.log(answer);*/
    api
      .post("v1/check-quiz", {
        id_user: user.id,
        id_course: course.id,
        id_quiz: idQuiz,
        question_id: idQuestion,
        answer: answer
      })
      .then((response) => {
        setAnswerAndLesson(false);
        setAnswerAndLesson(true);
        // setQuestions()
        //console.log(response.data.error);
        //console.log("RESPONSE:::",response);

        if (response.data.hits === 1) {
          addToast({
            type: "success",
            title: "Parabéns!",
            description: "Resposta correta!",
          });
        }else if (response.data.hits === 0) {
          addToast({
            type: "error",
            title: "Incorreto!",
            description: "Resposta incorreta!",
          });
        }else if(response.data.error === 'número máximo de tenativas excedido, Módulo temporariamente bloqueado'){
          addToast({
            type: "error",
            title: "Número máximo de tenativas excedido!",
            description: "Pergunta bloqueada",
          });
        }
      });
  }

  // Function to show video
  function handleShowVideo(lesson: LessonProps) {
    setModalShow(true);
    setUrlVideoLesson(lesson.video_url.replace(/\D/g, ""));
    setIdLesson(lesson.id);
    setQuestions({} as QuestionsProps);
    setLessonShow(lesson);
    setShowVideo(true);
    setShowQuestions(false);
  }

  //const bg = `https://userbox.com.br/ead_api/storage/app/public/courses/${course.image}`;
  const bg = `https://las.app.br/ead_api/storage/app/public/courses/${course.image}`;

  const handleCloseModalSlide = () => {
    setShowModalSlide(false);
  };

  useEffect(() => {
    // Verificar se algum modulo ja foi concluido
    const isThereAnyAssistedModule = courseModules.some(
      (course) => course.done
    );

    const firstModule = courseModules?.find((course) => course.first_module);

    if (!isThereAnyAssistedModule && firstModule) {
      setAvailableModules([firstModule]);
    } else if (courseModules.length) {
      const modulesAvailables = courseModules?.filter((course) => course.done);

      const lastModuleAvailable =
        modulesAvailables[modulesAvailables.length - 1];

      const lastModuleAvailableIndex = courseModules.indexOf(
        lastModuleAvailable
      );

      // busca o proximo modulo a partir do ultimo que possui a propriedade done
      const nextModule = (lastModuleAvailableIndex + 1) % courseModules.length;

      const currentlyAvailableModules = [
        ...modulesAvailables,
        courseModules[nextModule],
      ];

      setAvailableModules(currentlyAvailableModules);
    }
  }, [courseModules]);

  const handleFormatReleaseDateModule = (module: ModulesProps) =>
    module?.release_date.split(" ")[0];

  const handleVerifyReleaseDateModule = useCallback((module: ModulesProps) => {
    // se não tiver data de lançamento retorna true
    if (!module?.release_date) return true;

    // data atual no formato [yyyy, mm, dd]
    const currentDate = format(new Date(), "yyyy-MM-dd")
      .split("-")
      .map((item) => Number(item));

    // data de lançamento do modulo no formato [yyyy, mm, dd]
    const releaseDateModule = module?.release_date
      .split(" ")[0]
      .split("-")
      .map((item) => Number(item));

    /* se retorna 1 o modulo esta disponivel pois a data atual é posterior a data de lancamento
    se retornar -1 o modulo não está disponivel pois a data de lancamento não chegou
    se retornar 0 as data sao iguais */
    const compareReleaseDateWithCurrentDate = compareAsc(
      new Date(currentDate[0], currentDate[1] - 1, currentDate[2]),
      new Date(
        releaseDateModule[0],
        releaseDateModule[1] - 1,
        releaseDateModule[2]
      )
    );

    if (compareReleaseDateWithCurrentDate < 0) {
      return false;
    } else {
      return true;
    }
  }, []);

  const fetchData = () => {
    //setIsSubmitting(true);
    //Format Data
    //var dataObj = new Date(course.date_of_conclusion);
    api
      .post("v1/list-courses-complete", {
        id_course: id,
        id_user: user.id,
      })
      .then(({ data }) => {
      var dataConclusao = data.courses[0].date_of_conclusion;
      var dataObj = new Date(dataConclusao);
      var date = dataObj.getDate()+1;
      var month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][dataObj.getMonth()];
      var year = dataObj.getFullYear();
      var dataCompleta = date+' de '+month+' de '+year;
      console.log("DATA COMPLETA teste:", dataCompleta);
      gerarCertificadoPDF(user.name, course.name, cpfMask(user.cpf), courseModules, Math.trunc(course.total_hours), dataCompleta);
      setTimeout(function(){
        console.log("Waiting 3 seconds");
        setIsSubmitting(false);
      },3000)
    });
  }

  const handleVerifyModule = useCallback(
    (module: ModulesProps) => {
      // verifica se o modulo está no array de modulos disponiveis baseados na prop done
      const verifyModuleExists = availableModules.some(
        (moduleAvailable) => moduleAvailable.id === module.id
      );

      const verifyReleaseDateModule = handleVerifyReleaseDateModule(module);

      if (verifyReleaseDateModule && verifyModuleExists) {
        return true;
      } else {
        return false;
      }
    },
    [availableModules, handleVerifyReleaseDateModule]
  );
  
  const handleDownloadFile = (file: string) => {
    const link = document.createElement("a");
    //link.href = `https://userbox.com.br/ead_api/storage/app/public/lessons/${file}`;
    link.href = `https://las.app.br/ead_api/storage/app/public/lessons/${file}`;
    link.setAttribute("download", file);
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener");
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  };

 
  return (
    <S.Container bg={bg} >
      <Header />

      <S.Content>
        <S.Column>
          <S.ContentDescriptions>
            <S.TitleLessonModal>{course.name}</S.TitleLessonModal>
            <S.NameAuthor>{course.description}</S.NameAuthor>
          </S.ContentDescriptions>

          {courseModules.map((module, index) => (
            <>
              {index !== 0 ? ( 
                <>
                  <button
                    style={{
                      width: "100%",
                      padding: 0,
                      color: "#fff",
                      backgroundColor: "transparent",
                      border: "none",
                      opacity: handleVerifyModule(module) ? "1" : "0.7",
                      cursor: handleVerifyModule(module)
                        ? "pointer"
                        : "not-allowed",
                    }}
                    id={handleVerifyModule(module) ? `module_${module.id}` : ""}
                    key={module.id}
                  >
                    <S.ContentModule key={module.id} done={module.done}>
                      <S.TitleModule>{module.name}</S.TitleModule>
                      {!handleVerifyReleaseDateModule(module) && (
                        <S.ReleaseDateModule>
                          Data de lançamento:
                          <b>{handleFormatReleaseDateModule(module)}</b>
                        </S.ReleaseDateModule>
                      )}
                      <S.ButtonHideAndShow>
                        {module.done ? (
                          <FiCheckCircle size={22} color="#a0bf69" />
                        ) : (
                          <HiChevronDown size="20" color="#fff" />
                        )}
                      </S.ButtonHideAndShow>
                    </S.ContentModule>
                  </button>

                  {handleVerifyModule(module) && (
                    <UncontrolledCollapse toggler={`module_${module.id}`}>
                      <S.ContentOpen key={module.id}>
                        {module.lessons?.map((lesson) => (
                          <S.ButtonSelectLesson
                            key={lesson.id}
                            onClick={() => handleShowVideo(lesson)}
                          >
                            <S.TitleLesson>
                              <S.IconPlay
                                size={20}
                                color={
                                  lesson.lessonWatched ? "#056F2F" : "#7D7D7D"
                                }
                              />
                              {lesson.name}
                            </S.TitleLesson>
                          </S.ButtonSelectLesson>
                        ))}

                        {module.courseslide?.length !== 0 &&
                          module.courseslide?.map((courseSlide) => (
                            <S.ButtonSelectLesson
                              key={courseSlide.id}
                              onClick={() => handleOpenModalSlide(courseSlide)}
                            >
                              <S.TitleLesson>
                                <S.IconSlide size={20} />
                                <span>{courseSlide?.title}</span>
                              </S.TitleLesson>
                            </S.ButtonSelectLesson>
                          ))}

                        {module.quizzes?.map((quiz, index) => (                      
                          <>
                            <button
                              className="mt-2"
                              //className="mt-2"
                              //id={`module_${index}`}
                              onClick={() => {
                                toggleModalQuestion();
                                setCurrentQuiz(quiz);
                              }}
                              style={{
                                width: "100%",
                                color: "#fff",
                                backgroundColor: "#2d3036",
                                padding: 8,
                                borderColor: "transparent",
                                borderRadius: 5,
                              }}
                              id={`module_${index}`}
                            >
                              <S.ContentModule key={quiz.id}>
                                
                                <S.ButtonSelectLesson key={quiz.id}>
                                  <S.ButtonHideAndShow>
                                 
                                    <RiQuestionAnswerLine
                                      size={20}
                                      color={ "#FFF"
                                        //question.done?"#056F2F":"#7D7D7D"
                                      }
                                    />
                                  </S.ButtonHideAndShow>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      marginBottom: 0,
                                      color: "#fff",
                                    }}
                                    className="ml-2"
                                  >
                                    {" "}
                                    Questionário: {quiz.title}
                                  </p>
                                </S.ButtonSelectLesson>
                                
                              </S.ContentModule>
                            </button>
                          </>
                        ))}
                      </S.ContentOpen>
                    </UncontrolledCollapse>
                  )}
                </>
              ) : (
                <>
                  <button
                    style={{
                      width: "100%",
                      padding: 0,
                      color: "#fff",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={toggle}
                  >
                    <S.ContentModule key={module.id} done={module.done}>
                      <S.TitleModule>{module.name}</S.TitleModule>
                      <S.ButtonHideAndShow>
                        {module.done ? (
                          <FiCheckCircle size={22} color="#a0bf69" />
                        ) : (
                          <HiChevronDown size="20" color="#fff" />
                        )}
                      </S.ButtonHideAndShow>
                    </S.ContentModule>
                  </button>

                  <Collapse isOpen={isOpen}>
                    <S.ContentOpen key={module.id}>
                      {module.lessons?.map((lesson) => (
                        <S.ButtonSelectLesson
                          key={lesson.id}
                          onClick={() => handleShowVideo(lesson)}
                        >
                          <S.TitleLesson>
                            <S.IconPlay
                              size={20}
                              color={
                                lesson.lessonWatched ? "#056F2F" : "#7D7D7D" //"#7D7D7D" : "#056F2F"
                              }
                            />
                            {lesson.name}
                          </S.TitleLesson>
                        </S.ButtonSelectLesson>
                      ))}

                      {module.courseslide?.length !== 0 &&
                        module.courseslide?.map((courseSlide) => (
                          <S.ButtonSelectLesson
                            key={courseSlide.id}
                            onClick={() => handleOpenModalSlide(courseSlide)}
                          >
                            <S.TitleLesson>
                              <S.IconSlide size={20} />
                              <span>{courseSlide?.title}</span>
                            </S.TitleLesson>
                          </S.ButtonSelectLesson>
                        ))}

                      
                      {module.quizzes?.map((quiz, index) => (
                        <>
                          <button
                            className="mt-2"
                            id={`module_${index}`}
                            onClick={() => {
                              toggleModalQuestion();
                              setCurrentQuiz(quiz);
                            }}
                            style={{
                              width: "100%",
                              color: "#fff",
                              backgroundColor: "#2d3036",
                              padding: 8,
                              borderColor: "transparent",
                              borderRadius: 5,
                            }}
                          >
                            
                            <S.ButtonSelectLesson key={quiz.id}>
                              <S.ButtonHideAndShow>
                                <RiQuestionAnswerLine
                                  size={20}
                                  color={ "#FFF"
                                        //question.done?"#056F2F":"#7D7D7D"
                                    }
                                />
                              </S.ButtonHideAndShow>
                              <p
                                style={{
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  marginBottom: 0,
                                  color: "#fff",
                                }}
                                className="ml-2"                              
                              >
                                Questionário: {quiz.title}
                              </p>
                              </S.ButtonSelectLesson>
                           
                          </button>
                        </>
                      ))}
                      
                    </S.ContentOpen>
                  </Collapse>
                </>
              )/**/}
            </>
          ))}
          <S.ButtonGerarCertificado
          hidden={courseDone ? false : true}
          //onSubmit={() => submitDelay()}
          disabled={isSubmitting}
          onClick={() => {
            setIsSubmitting(true)
            fetchData()
            }
          }
          >
            {isSubmitting ? <Spinner size="sm" /> : "Gerar Certificado"}
          </S.ButtonGerarCertificado>
        </S.Column>        
      </S.Content>
      <Footer />

      <S.ModalVideo modalShow={modalShow} fill="10" bg="#000">
        <S.ButtonBack onClick={() => setModalShow(false)}>
          <S.IconBack />
          Voltar à navegação
        </S.ButtonBack>

        <S.VimeoPlayer
          video={urlVideoLesson !== undefined ? urlVideoLesson : ""}
          autoplay
          width="900"
          height="500"
          onEnd={() => callbackEndVideo()}
        />

        <S.GroupDataVideo>
          <div>
            <S.TitleLessonModal>{lessonShow?.name}</S.TitleLessonModal>
            <S.NameAuthor>{lessonShow?.description}</S.NameAuthor>
          </div>

          {lessonShow?.file && (
            <S.ButtonDownloadFile
              onClick={() => handleDownloadFile(lessonShow?.file)}
            >
              Baixar arquivo da aula{" "}
              <HiOutlineDownload size={20} color="#fff" />
            </S.ButtonDownloadFile>
          )}
        </S.GroupDataVideo>
      </S.ModalVideo>

      <ModalCourseSlide
        modalIsOpen={showModalSlide}
        closeModal={handleCloseModalSlide}
        slides={lessonSlides}
      />

      <ModalQuestion
        toggle={toggleModalQuestion}
        modal={modalQuestion}
        quiz={currentQuiz}
        showQuestions={showQuestions}
        questions={questions}
        handleFormToAnswer={handleFormToAnswer}
        setAnswer={setAnswer}
      />
    </S.Container>
  );
};

export default Course;
