import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  // eslint-disable-next-line
  MouseEvent,
} from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/ToastContext";
import api from "../../services/api";
import Modal from "react-modal";
import ModalCreateQuiz from "../../components/ModalCreateQuizz";
import { RiSlideshowLine, RiQuestionnaireFill } from "react-icons/ri";
import {
  HiOutlineTrash,
  HiOutlineExclamation,
  HiOutlinePencilAlt,
  HiOutlineDocumentText,
  HiOutlineUpload,
  HiOutlineCog,
} from "react-icons/hi";
import { GreenMain } from "../../styles/colors";
import Upload from "../../assets/upload.svg";
import { FaChevronDown } from "react-icons/fa";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Label from "../../components/Label";
import ModalSlide from "../../components/ModalSlide";
import ModalQuestion from "../../components/ModalCreateQuestion";
import { Link } from "react-scroll";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { Row, Col, FormGroup, Form, Spinner } from "reactstrap";
import { format } from "date-fns";
import * as S from "./styles";

interface ParamsProps {
  id: string;
}

interface LessonProps {
  id: number;
  name: string;
  video_url: string;
  file: string;
}

interface SlideProps {
  file: string;
  id: number;
  id_slide: number;
}

interface Quizzes {
  title: string;
  id: number;
  questions: number;
}

interface SlideContent {
  description: string;
  id: number;
  id_module: number;
  slides: Array<SlideProps>;
  title: string;
}

interface ModulesListProps {
  id: number;
  id_course: number;
  name: string;
  lessons?: Array<LessonProps>;
  courseslide?: Array<SlideContent>;
  quizzes: Array<Quizzes>;
}

interface IDepartments {
  id: number;
  name: string;
}

const AddCourse: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const [title, setTitle] = useState("");
  const [cargaHoraria, setCargaHoraria] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<File>();
  const [previewImage, setPreviewImage] = useState<string>();
  const [modules, setModules] = useState<boolean>(false);
  const [isSubmittingCourse, setIsSubmittingCourse] = useState<boolean>(false);
  const [isSubmittingModule, setIsSubmittingModule] = useState<boolean>(false);
  const [isSubmittingVideo, setIsSubmittingVideo] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState<string>();
  const [courseID, setCourseID] = useState<number>();
  const [moduleID, setModuleID] = useState<number>();
  const [lessonName, setLessonName] = useState<any>();
  const [lessonLink, setLessonLink] = useState<any>();
  const [dateLaunch, setDateLaunch] = useState<string>();
  const [modulesList, setModulesList] = useState<ModulesListProps[] | any>();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [question, setQuestion] = useState<string>();
  const [optionOne, setOptionOne] = useState<string>();
  const [optionTwo, setOptionTwo] = useState<string>();
  const [optionThree, setOptionThree] = useState<string>();
  const [optionFour, setOptionFour] = useState<string>();
  const [optionFive, setOptionFive] = useState<string>();

  const [descriptionQuiz, setDescriptionQuiz] = useState<string>();
  const [correctOption, setCorrectOption] = useState<string>();
  const [titleQuiz, setTitleQuiz] = useState<string>("");
  const [titleQuestion, setTitleQuestion] = useState<string>("");
  const [departmentsSelected, setDepartmentsSelected] = useState<
    IDepartments[]
  >([]);
  const [departments, setDepartments] = useState<IDepartments[]>([]);
  const [showOptionsDepartment, setShowOptionsDepartment] = useState<boolean>(
    false
  );

  const [showModalSlide, setShowModalSlide] = useState<boolean>(false);
  const [currentModule, setCurrentModule] = useState<ModulesListProps>();

  const [savedSlides, setSavedSlides] = useState<boolean>(false);

  const [modalCreateQuiz, setModalCreateQuiz] = useState(false);
  const toggleModalCreateQuiz = () => setModalCreateQuiz(!modalCreateQuiz);

  const [idQuiz, setIdQuiz] = useState<number>(0);
  const [modulePostion, setModulePosition] = useState<number>(0);
  const [modalCreateQuestion, setModalCreateQuestion] = useState(false);
  const [positionQuiz, setPositionQuiz] = useState<number>(0);
  const [dataQuiz, setDataQuiz] = useState<any>({});
  const [dataCourseEdit, setDataCourseEdit] = useState<any>({});
  const [isEdition, setIsEdition] = useState<boolean>(false);
  const [fileLesson, setFileLesson] = useState<File>();
  const [isEditingLesson, setIsEditingLesson] = useState<boolean>(false);
  const [idLessonForEditing, setIdLessonForEditing] = useState<number | null>(
    null
  );
  const [previewFileLesson, setPreviewFileLesson] = useState<string>("");

  const selectDepartment = useRef(null);

  const { addToast } = useToast();

  const toggleModalCreateQuestion = () => {
    handleGetCourseData();
    setModalCreateQuestion(!modalCreateQuestion);
  };

  useEffect(() => {
    api.get("v1/departament").then((response) => {
      setDepartments(response.data.data);
    });
  }, []);

  
  

  useEffect(() => {
    if (id) {
      const handleGetDataCourseEdit = async () => {
        //async function handleGetDataCourseEdit() {
          try {
            await api
              .post("v1/list-courses-complete", { id_course: id })
              .then(({ data }) => {
                const course = data?.courses[0];
                if (course) {
                  setCourseID(course.id);
                  setTitle(course.name);
                  setDescription(course.description);
                  setModules(true);
                  setDataCourseEdit(course);
                  setModulesList(course?.modules);
                  setDepartmentsSelected(course?.departaments);
                  setIsEdition(true);
                }
              });
          } catch {
            addToast({
              type: "error",
              title: "Ocorreu um erro ao abrir o curso",
            });
          }
      }
      handleGetDataCourseEdit()
    }
    // eslint-disable-next-line
  }, [id]);

  const handleGetCourseData = async () => {
    try {
      await api
        .post("v1/list-courses-complete", { id_course: courseID })
        .then(({ data }) => {
          setModulesList(data.courses[0].modules);
        });
    } catch {
      addToast({
        type: "error",
        title: "Erro ao Atualizar Curso",
      });
    }
  };

  const handleCreateModule = async () => {
    try {
      if (!moduleName) {
        return addToast({
          type: "error",
          title: "O nome do módulo é obrigatório",
        });
      }

      const formattedDate = !!dateLaunch
        ? dateLaunch?.split("/").reverse().join("/").split("/")
        : false;

      //console.log(dateLaunch);

      if (formattedDate && formattedDate?.length !== 3) {
        return addToast({
          type: "error",
          title: "Data de lançamento inválida",
        });
      }

      const dateTime = formattedDate
        ? format(
            new Date(
              Number(formattedDate[0]),
              Number(formattedDate[1]) - 1,
              Number(formattedDate[2])
            ),
            "yyyy-MM-dd'T'03:00:00"
          )
        : null;

      const data = {
        name: moduleName,
        id_course: courseID,
        release_date: dateTime,
        description: "",
      };

      setIsSubmittingModule(true);

      const { data: result } = await api.post("v1/module", data);

      setModuleID(result.id);
      setModuleName("");
      setDateLaunch("");
      setIsSubmittingModule(false);

      const { data: resultCompleteCourses } = await api.post(
        "v1/list-courses-complete",
        {
          id_course: result.id_course,
        }
      );
      setModulesList(resultCompleteCourses.courses[0].modules);

      addToast({
        type: "success",
        title: "Módulo criado com sucesso",
      });
    } catch {
      setIsSubmittingModule(false);
      return addToast({
        type: "error",
        title: "Falha ao Criar Módulo. Tente novamente",
      });
    }
  };

  const handleCreateQuizz = async (title: String, description: String) => {
    try {
      const dataQuiz = {
        id_module: moduleID,
        description: description,
        title: title,
      };
      // eslint-disable-next-line
      const { data: resultQuiz } = await api.post("v1/quiz", dataQuiz);
      handleGetCourseData();
    } catch {
      addToast({
        type: "error",
        title: "Erro ao cadastrar Quiz",
        description: "Algo deu errado, tente novamentet mais tarde.",
      });
    }
  };


  useEffect(() => {
    if (!!currentModule) {
      const getData = async () => {
        try {
          api.get(`v1/get-module-slide/${currentModule?.id}`).then((response) => {
            if (savedSlides) {
              const { id_course } = response.data;
              api
                .post("v1/list-courses-complete", { id_course: id_course })
                .then(({ data }) => {
                  setModulesList(data.courses[0].modules);
                });
    
              setSavedSlides(false);
            }
          });
        } catch {}
      };
      getData();
    }
  }, [savedSlides, currentModule]);

  const handleGetData = async () => {
    try {
      await api
        .post("v1/list-courses-complete", { id_course: courseID })
        .then(({ data }) => {
          setModulesList(data.courses[0].modules);
        });
    } catch {}
  };

  const handleSelectImage = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) return;

    setImage(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleSelectFileLesson = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    if (!event.target.files) return;

    // console.log("arquivo:", event.target.files[0]);

    setFileLesson(event.target.files[0]);
    if (previewFileLesson) {
      setPreviewFileLesson("");
    }
  };

  const handleSubmit = async () => {
    // if (dataCourseEdit?.image) {
    //   setPreviewImage("true");
    // }

    if (!previewImage || !title || !description) {
      return addToast({
        type: "error",
        title: "Campos obrigatórios",
        description: "Todos os campos e a imagem são obrigatórios",
      });
    }

    const departmentsIds: number[] = [];
    const data = new FormData();

    departmentsSelected.map((department: IDepartments) => {
      return departmentsIds.push(department.id);
    });

    data.append("image", Object(image), image?.name);
    data.append("name", title);
    data.append("description", description);
    data.append("departaments", String(departmentsIds));
    //Adicionada Carga horária
    data.append("total_hours", cargaHoraria);

    setIsSubmittingCourse(true);
    if (isEdition) {
      data.append("_method", "PUT");

      await api
        .post(`v1/course/${courseID}`, data)
        .then(({ data }) => {
          setCourseID(data.id);
          //DADOS RETORNADOS DA API
          //console.log("RETORNO API CURSO:::",data);
          addToast({
            type: "success",
            title: "Dados do curso atualizado com sucesso",
            description: `O curso ${data.name} foi atualizado com sucesso`,
          });
        })
        .catch((err) => {
          setIsSubmittingCourse(false);
        });
    } else {
      await api
        .post("v1/course", data)
        .then(({ data }) => {
          setCourseID(data.id);
          //DADOS RETORNADOS DA API
          //console.log("RETORNO API CURSO:::",data);
          addToast({
            type: "success",
            title: "Criado com sucesso",
            description: `O curso ${data.name} foi criado com sucesso`,
          });
        })
        .catch((err) => {
          setIsSubmittingCourse(false);
        });
    }

    setIsSubmittingCourse(false);
    setModules(true);
  };

  const addLesson = async (id_module: any, name: any, link: any) => {
    setIsSubmittingVideo(true);

    if (!lessonName || !lessonLink) {
      setIsSubmittingVideo(false);

      return addToast({
        type: "error",
        title: "Verifique o nome e o video da aula",
      });
    }

    const data = new FormData();
    fileLesson && data.append("file", Object(fileLesson), fileLesson?.name);
    data.append("description", "");
    data.append("id_module", id_module);
    data.append("name", lessonName);
    data.append("video_url", lessonLink);

    if (isEditingLesson) {
      data.append("_method", "PUT");
      await api
        .post(`v1/lesson/${idLessonForEditing}`, data)
        .then(() => {
          setIsEditingLesson(false);
          return addToast({
            type: "success",
            title: "Aula atualizada com sucesso",
          });
        })
        .catch((err) => {
          setIsSubmittingVideo(false);
          setIsEditingLesson(false);
          return addToast({
            type: "error",
            title: "Ocorreu um erro ao atualizar a aula",
          });
        });
    } else {
      await api
        .post("v1/lesson", data)
        .then(() => {
          return addToast({
            type: "success",
            title: "Aula cadastrada com sucesso",
          });
        })
        .catch((err) => {
          setIsSubmittingVideo(false);
          return addToast({
            type: "error",
            title: "Ocorreu um erro ao cadastrar a aula",
          });
          //addToast('Saved Successfully', { appearance: 'success' });
        });
    }

    setIsSubmittingVideo(false);

    await api
      .post("v1/list-courses-complete", { id_course: courseID })
      .then(({ data }) => {
        setModulesList(data.courses[0].modules);
      });

    name.value = "";
    link.value = "";
    setLessonLink("");
    setLessonName("");
    setFileLesson(undefined);
  };

  const createFile = async (fileName: string) => {
    const response = await fetch(
      //`https://userbox.com.br/ead_api/storage/app/public/courses/${fileName}`
      `https://las.app.br/ead_api/storage/app/public/courses/${fileName}`
    );
    const data = await response.blob();
    // eslint-disable-next-line
    const file = new File([data], fileName);
    //console.log(file);
    // ... do something with the file or return it
  };

  const handleEditLesson = (lesson: LessonProps) => {
    setLessonLink(lesson.video_url);
    setLessonName(lesson.name);
    setPreviewFileLesson(lesson.file);
    setIdLessonForEditing(lesson.id);
    setIsEditingLesson(true);

    createFile(lesson.file);
  };

  const removeLesson = async (id: number) => {
    await api.delete(`v1/lesson/${id}`).then((data) => {
      api
        .post("v1/list-courses-complete", { id_course: courseID })
        .then(({ data }) => {
          setModulesList(data.courses[0].modules);
        });
    });
  };

  const removeModule = async (id: number) => {
    await api.delete(`v1/module/${id}`).then(() => {
      api
        .post("v1/list-courses-complete", { id_course: courseID })
        .then(({ data }) => {
          setModulesList(data.courses[0].modules);
        });
    });
  };

  const [arrayQuestions, setArrayQuestions] = useState<any>([]);

  const handleAddtQuiz = () => {
    const array = arrayQuestions;
    const data = {
      title: titleQuestion,
      description: question,
      option_1: optionOne,
      option_2: optionTwo,
      option_3: optionThree,
      option_4: optionFour,
      option_5: optionFive,
      correct_option: correctOption,
    };
    array.push(data);
    setArrayQuestions([...array]);
    setQuestion("");
    setOptionOne("");
    setOptionTwo("");
    setOptionThree("");
    setOptionFour("");
    setOptionFive("");
  };

  const handleRemoveQuiz = (index: number) => {
    const array = arrayQuestions;
    array.splice(index, 1);
    setArrayQuestions([...array]);
  };

  const handleSubmitQuiz = async () => {
    if (arrayQuestions === 0) {
      return addToast({
        type: "error",
        title: "Adicione as perguntas antes de criar o quiz",
      });
    }

    try {
      const dataQuiz = {
        id_module: moduleID,
        description: descriptionQuiz,
        title: titleQuiz,
      };
      const { data: resultQuiz } = await api.post("v1/quiz", dataQuiz);
      //console.log(resultQuiz);

      for (let i = 0; i < arrayQuestions.length; i++) {
        // eslint-disable-next-line
        const { data: resultQuestion } = await api.post("v1/question", {
          title: arrayQuestions[i].title,
          id_quiz: resultQuiz.id,
          description: arrayQuestions[i].description,
          option_1: arrayQuestions[i].option_1,
          option_2: arrayQuestions[i].option_2,
          option_3: arrayQuestions[i].option_3,
          option_4: arrayQuestions[i].option_4,
          option_5: arrayQuestions[i].option_5,
          correct_option: parseInt(arrayQuestions[i].correct_option),
        });
        handleGetData();
        closeModal();
      }
    } catch (e) {
      //console.log(e);
      addToast({
        type: "error",
        title: "Erro ao cadastrar Quiz",
        description: "Algo deu errado, tente novamentet mais tarde.",
      });
    }
  };

  function closeModal() {
    setQuestion("");
    setOptionOne("");
    setOptionTwo("");
    setOptionThree("");
    setOptionFour("");
    setOptionFive("");
    setDescriptionQuiz("");
    setTitleQuiz("");
    setTitleQuestion("");
    setArrayQuestions([]);
    setIsOpen(false);
  }

  const handleCloseModalSlide = () => {
    setShowModalSlide(false);
  };

  const handleOpenModalSlide = (module: ModulesListProps) => {
    setShowModalSlide(true);
    setCurrentModule(module);
  };

  const handleSavedSlides = () => {
    setSavedSlides(true);
  };

  const handleGetNumberQuestions = (data: any) => {
    return data.length;
  };

  const handleDeleteQuiz = async (id: Number) => {
    try {
      await api.delete(`v1/quiz/${id}`);
      handleGetCourseData();
    } catch {}
  };

  const handleAddDepartment = (departmentAdd: IDepartments) => {
    setShowOptionsDepartment(false);
    const auxDepartmentsUser = [...departmentsSelected];
    // se o departamento ja estiver selecionado, não adiciona novamente
    const departmentAlreadySelected = auxDepartmentsUser.find(
      (dept) => dept.id === departmentAdd.id
    );
    if (departmentAlreadySelected) return;

    auxDepartmentsUser.push(departmentAdd);
    setDepartmentsSelected(auxDepartmentsUser);
  };

  const handleRemoveDepartment = (department: IDepartments) => {
    setShowOptionsDepartment(false);
    const auxDepartmentsUser = [...departmentsSelected];

    const departmentPosition = auxDepartmentsUser.findIndex(
      (dep) => dep.id === department.id
    );

    auxDepartmentsUser.splice(departmentPosition, 1);
    setDepartmentsSelected(auxDepartmentsUser);
  };

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showOptionsDepartment]);

  function handleClickOutside(event: any) {
    if (
      selectDepartment?.current &&
      // @ts-ignore
      !selectDepartment?.current?.contains(event.target)
    ) {
      setShowOptionsDepartment(false);
    }
  }

  const handleSelectAllDepartments = (e: any) => {
    setDepartmentsSelected(e.target.checked ? departments : []);
  };

  const handleDateMask = (value: string) => {
    const dateMask = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1");

    setDateLaunch(dateMask);
  };

  return (
    <S.Container>
      <Header />

      <S.Content>
        <S.WrapperCourseInfo>
          <S.ContentCourseInfo>
            <S.LabelImage htmlFor="image">
              {previewImage ? (
                <S.ImagePreview src={previewImage} alt={previewImage} />
              ) : dataCourseEdit?.image ? (
                <S.ImagePreview
                  //src={`https://userbox.com.br/ead_api/storage/app/public/courses/${dataCourseEdit?.image}`}
                  src={`https://las.app.br/ead_api/storage/app/public/courses/${dataCourseEdit?.image}`}
                  alt="image"
                />
              ) : (
                <>
                  <S.ImageUpload src={Upload} alt="upload" />
                  <S.InfoImageUpload>
                    Clique aqui para carregar a imagem
                  </S.InfoImageUpload>
                </>
              )}
            </S.LabelImage>

            <input
              type="file"
              className="hidden"
              accept="image/*"
              id="image"
              onChange={handleSelectImage}
            />
          </S.ContentCourseInfo>

          <S.ContentCourseInfo>
            <>
              <Label>Departamento:</Label>
              <div>
                <S.DepartmentSelect ref={selectDepartment}>
                  <S.DepartmentSelectButton
                    onClick={() =>
                      departments.length >= 1 && setShowOptionsDepartment(true)
                    }
                    show={showOptionsDepartment}
                  >
                    {departmentsSelected.map((department: IDepartments) => (
                      <S.BadgeDepartment>
                        {department.name}
                        <span
                          onClick={() => handleRemoveDepartment(department)}
                        />
                      </S.BadgeDepartment>
                    ))}
                    {departmentsSelected.length <= 0 && (
                      <span>Selecione pelo menos 1 departamento</span>
                    )}
                    <FaChevronDown
                      size={14}
                      color="#fff"
                      className="arrow-down"
                    />
                  </S.DepartmentSelectButton>
                  <S.DepartmentOptions show={showOptionsDepartment}>
                    {departments.map((department) => (
                      <span
                        onClick={() => handleAddDepartment(department)}
                        key={department.id}
                      >
                        {department.name}
                      </span>
                    ))}
                  </S.DepartmentOptions>
                </S.DepartmentSelect>
              </div>
            </>
            <S.GroupCheckbox>
              <input
                id="checkbox-departments"
                type="checkbox"
                onClick={handleSelectAllDepartments}
              />
              <label htmlFor="checkbox-departments">
                Selecionar todos os departamentos
              </label>
            </S.GroupCheckbox>

            <S.GroupTitleCourse>
              <Label>Título:</Label>
              <S.InputTitle
                type="text"
                placeholder="Digite o título do curso"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </S.GroupTitleCourse>

            <S.GroupTitleCourse>
              <Label>Carga Horária:</Label>
              <input
                type="number"
                placeholder="Quantidade de horas"
                value={cargaHoraria}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e:any) => setCargaHoraria(e.target.value)}
              />
            </S.GroupTitleCourse>

            <S.WrapperTextArea>
              <Label>Descrição ou nome do palestrante:</Label>
              <S.TextArea
                placeholder="Digite a descrição do curso ou o nome do palestrante"
                rows={5.2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </S.WrapperTextArea>
          </S.ContentCourseInfo>
        </S.WrapperCourseInfo>

        <Button className="button-saved">
          <Link
            activeClass="active"
            to="add-module"
            spy={true}
            delay={400}
            smooth={true}
            offset={-70}
            duration={1000}
            onClick={handleSubmit}
          >
            {isSubmittingCourse ? (
              <Spinner size="sm" />
            ) : isEdition ? (
              "Atualizar"
            ) : (
              "Salvar"
            )}
          </Link>
        </Button>
      </S.Content>

      <S.ContainerAddModule id="add-module" padding={!!modules}>
        {modules && (
          <Form>
            <FormGroup className="input-module">
              <Label>Módulo</Label>
              <Input
                type="text"
                name="module"
                id="module"
                placeholder="Digite o título do módulo"
                style={{ marginTop: "10px" }}
                value={moduleName}
                onChange={(e) => setModuleName(e.target.value)}
              />
            </FormGroup>

            <FormGroup className="input-module">
              <Label>Data de lançamento</Label>
              <Input
                type="text"
                name="dateLaunch"
                id="dateLaunch"
                placeholder="Digite a data de lançamento do módulo"
                style={{ marginTop: "10px" }}
                value={dateLaunch}
                onChange={(e) => handleDateMask(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="submit-module">
              <Button>
                <Link
                  activeClass="active"
                  to={modulesList?.length > 1 ? "last-module" : "first-module"}
                  spy={true}
                  delay={200}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                  onClick={handleCreateModule}
                >
                  {isSubmittingModule ? <Spinner size="sm" /> : "Salvar"}
                </Link>
              </Button>
            </FormGroup>
          </Form>
        )}
      </S.ContainerAddModule>

      {modules && (
        <section id="first-module">
          {modulesList?.map((module: ModulesListProps, index: number) => (
            <S.Modules key={module.id}>
              <S.ContainerHeaderModule>
                <S.HeaderModule>
                  <div>
                    <h1>{module.name}</h1>
                  </div>

                  <div className="containerHeaderButton">
                    <Button
                      onClick={() => {
                        toggleModalCreateQuiz();
                        setModuleID(module.id);
                      }}
                    >
                      <RiQuestionnaireFill
                        size={20}
                        className="mr-2"
                        color="#fff"
                      />{" "}
                      Criar Quizz
                    </Button>

                    <Button
                      onClick={() => handleOpenModalSlide(module)}
                      className="ml-4"
                    >
                      <RiSlideshowLine
                        size={20}
                        className="mr-2"
                        color="#fff"
                      />
                      Adicionar Slide
                    </Button>

                    <Button
                      danger
                      className="ml-4"
                      onClick={() => {
                        removeModule(module.id);
                      }}
                    >
                      <HiOutlineTrash size={20} color="#fff" className="mr-2" />
                      Remover Módulo
                    </Button>
                  </div>
                </S.HeaderModule>

                <S.GroupDataModule>
                  <Form>
                    <FormGroup>
                      <Label>Nome da aula:</Label>
                      <Input
                        style={{ color: "#000", marginTop: "10px" }}
                        type="text"
                        name={`name-${module.id}`}
                        placeholder="Ex: Aula 01"
                        id={`name-${module.id}`}
                        value={lessonName}
                        onChange={(e) => setLessonName(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Url do video:</Label>
                      <Input
                        style={{ color: "#000", marginTop: "10px" }}
                        type="text"
                        name={`link-${module.id}`}
                        placeholder="Ex: http://vimeo.com/8HUhGY78"
                        id={`link-${module.id}`}
                        onChange={(e) => setLessonLink(e.target.value)}
                        value={lessonLink}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Arquivo da aula:</Label>
                      <label htmlFor="fileLesson">
                        <HiOutlineUpload color="#fff" size={20} />
                        Adicionar arquivo
                      </label>

                      {!!fileLesson && (
                        <S.FileLesson>
                          <div>
                            <p>
                              <HiOutlineExclamation
                                size={20}
                                color={GreenMain}
                              />
                              Fique atendo, aqui você pode visualizar e excluir
                              o arquivo da aula antes de definitivamente{" "}
                              {isEditingLesson ? "atualizar" : "criar"} a aula.
                            </p>
                          </div>
                          <span className="fileName">
                            <HiOutlineDocumentText color="#fff" size={18} />
                            {fileLesson?.name}

                            <S.ButtonRemoveFileLesson
                              onClick={() => setFileLesson(undefined)}
                            >
                              <HiOutlineTrash size={20} color="#fff" />
                            </S.ButtonRemoveFileLesson>
                          </span>
                        </S.FileLesson>
                      )}

                      {!!previewFileLesson && (
                        <S.FileLesson>
                          <div>
                            <p>
                              <HiOutlineExclamation
                                size={20}
                                color={GreenMain}
                              />
                              Abaixo você pode visualizar o arquivo que já está
                              cadastrado na aula, para alterar o arquivo faça um
                              novo upload
                            </p>
                          </div>
                          <span className="fileName">
                            <HiOutlineDocumentText color="#fff" size={18} />
                            <a
                              //href={`https://userbox.com.br/ead_api/storage/app/public/lessons/${previewFileLesson}`}
                              href={`https://las.app.br/ead_api/storage/app/public/lessons/${previewFileLesson}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Visualizar arquivo da aula
                            </a>
                          </span>
                        </S.FileLesson>
                      )}

                      <input
                        type="file"
                        id="fileLesson"
                        accept="application/pdf"
                        onChange={handleSelectFileLesson}
                      />
                    </FormGroup>

                    <Button
                      className="btn-add-video"
                      onClick={() => {
                        addLesson(
                          module.id,
                          document.getElementById(`name-${module.id}`),
                          document.getElementById(`link-${module.id}`)
                        );
                      }}
                    >
                      {isSubmittingVideo ? (
                        <Spinner size="sm" />
                      ) : isEditingLesson ? (
                        "Atualizar aula"
                      ) : (
                        "Cadastrar aula"
                      )}
                    </Button>
                  </Form>
                </S.GroupDataModule>

                {module.lessons?.length !== 0 && (
                  <S.ListLesson>
                    <div>
                      <h1>Vídeo-aulas cadastradas</h1>
                      <p className="mt-1">
                        <HiOutlineExclamation size={20} color={GreenMain} />
                        Fique atendo, aqui você pode visualizar ou excluir a
                        vídeo aula
                      </p>
                    </div>

                    <div>
                      {module.lessons?.map((lesson) => (
                        <S.ListLessonsModule>
                          <S.NameAndLink>
                            Nome da aula
                            <p>{lesson.name}</p>
                          </S.NameAndLink>
                          <S.NameAndLink>
                            Url
                            <p>{lesson.video_url}</p>
                          </S.NameAndLink>
                          {lesson?.file ? (
                            <S.NameAndLink>
                              Arquivo
                              <a
                                //href={`https://userbox.com.br/ead_api/storage/app/public/lessons/${lesson.file}`}
                                href={`https://las.app.br/ead_api/storage/app/public/lessons/${lesson.file}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Arquivo da aula
                              </a>
                            </S.NameAndLink>
                          ) : (
                            <div></div>
                          )}

                          <S.GroupButtonLesson>
                            {isEdition && (
                              <S.ButtonEditLesson
                                onClick={() => handleEditLesson(lesson)}
                              >
                                <HiOutlineCog size={20} color="#fff" />
                              </S.ButtonEditLesson>
                            )}

                            <S.ButtonRemoveLesson
                              onClick={() => removeLesson(lesson.id)}
                            >
                              <HiOutlineTrash size={20} color="#fff" />
                            </S.ButtonRemoveLesson>
                          </S.GroupButtonLesson>
                        </S.ListLessonsModule>
                      ))}
                    </div>
                  </S.ListLesson>
                )}

                {module.courseslide?.length !== 0 && (
                  <S.ListLesson>
                    <Row>
                      <Col md={12}>
                        <div>
                          <h1>Slides Cadastrados</h1>
                          <p className="mt-1">
                            <HiOutlineExclamation size={20} color={GreenMain} />
                            Fique atendo, aqui você pode visualizar ou excluir
                            os Slides
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          {module.courseslide?.map((course) => (
                            <S.ListLessonsModule>
                              <S.NameAndLink>
                                <S.IconSlide size={20} />
                                Nome do slide: {course?.title}
                              </S.NameAndLink>
                              <S.NameAndLink>
                                Descrição: {course?.description}
                              </S.NameAndLink>
                              <S.NameAndLink>
                                Slides: {course?.slides.length}
                              </S.NameAndLink>

                              <S.ButtonRemoveLesson>
                                <HiOutlineTrash size={20} color="#fff" />
                              </S.ButtonRemoveLesson>
                            </S.ListLessonsModule>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </S.ListLesson>
                )}

                {module.quizzes?.length !== 0 && (
                  <S.ListLesson>
                    <Row>
                      <Col md={12}>
                        <div>
                          <h1>Questionários cadastrados</h1>
                          <p className="mt-1">
                            <HiOutlineExclamation size={20} color={GreenMain} />
                            Fique atendo, aqui você adicionar ou remover
                            questões de cada Quiz cadastrado. Alem de
                            exclui-los.
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          {module.quizzes?.map((quizze, indexQuiz) => (
                            <S.ListLessonsModule>
                              <S.NameAndLink>
                                <RiQuestionnaireFill
                                  color="#fff"
                                  className="mr-2"
                                  size={20}
                                />
                                {quizze.title}
                              </S.NameAndLink>
                              <S.NameAndLink>
                                Quantidade de questões:{" "}
                                {handleGetNumberQuestions(quizze?.questions)}
                              </S.NameAndLink>
                              <div>
                                <S.ButtonEditQuizz
                                  onClick={() => {
                                    setPositionQuiz(quizze.id);
                                    setModulePosition(index);
                                    setIdQuiz(quizze.id);
                                    setDataQuiz(quizze);
                                    toggleModalCreateQuestion();
                                  }}
                                >
                                  <HiOutlinePencilAlt color="#fff" size={20} />
                                </S.ButtonEditQuizz>

                                <S.ButtonRemoveLesson
                                  onClick={() => handleDeleteQuiz(quizze.id)}
                                >
                                  <HiOutlineTrash size={20} color="#fff" />
                                </S.ButtonRemoveLesson>
                              </div>
                            </S.ListLessonsModule>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </S.ListLesson>
                )}
              </S.ContainerHeaderModule>
            </S.Modules>
          ))}
        </section>
      )}

      <section id="last-module">
        <Footer />
      </section>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
          content: {
            position: "absolute",
            top: "5%",
            left: "25%",
            right: "25%",
            // bottom: '15%',
            border: "1px solid #191C22",
            background: "#191C22",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
        contentLabel="Example Modal"
      >
        <S.ContentModal>
          <S.TitleQuiz>Quiz</S.TitleQuiz>
          <Label>Titulo quiz</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a pergunta"
            value={titleQuiz}
            onChange={(event) => setTitleQuiz(event.target.value)}
          />

          <Label>Descrição </Label>
          <S.TextInput
            type="text"
            placeholder="Digite a pergunta"
            value={descriptionQuiz}
            onChange={(event) => setDescriptionQuiz(event.target.value)}
          />

          <S.TitleQuiz>Questionário</S.TitleQuiz>

          <Label>Título</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a pergunta"
            value={titleQuestion}
            onChange={(event) => setTitleQuestion(event.target.value)}
          />

          <Label>Pergunta:</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a pergunta"
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
          />

          <Label>Alternativa 01:</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a alternativa 01"
            value={optionOne}
            onChange={(event) => setOptionOne(event.target.value)}
          />

          <Label>Alternativa 02:</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a alternativa 02"
            value={optionTwo}
            onChange={(event) => setOptionTwo(event.target.value)}
          />

          <Label>Alternativa 03:</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a alternativa 03"
            value={optionThree}
            onChange={(event) => setOptionThree(event.target.value)}
          />

          <Label>Alternativa 04:</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a alternativa 04"
            value={optionFour}
            onChange={(event) => setOptionFour(event.target.value)}
          />

          <Label>Alternativa 05:</Label>
          <S.TextInput
            type="text"
            placeholder="Digite a alternativa 05"
            value={optionFive}
            onChange={(event) => setOptionFive(event.target.value)}
          />

          <Label>Alternativa correta</Label>

          <select
            style={{ width: "100%", marginBottom: 10 }}
            value={correctOption}
            onChange={(event) => setCorrectOption(event.target.value)}
            id="option"
            name="option"
          >
            <option value="1">Questão 1</option>
            <option value="2">Questão 2</option>
            <option value="3">Questão 3</option>
            <option value="4">Questão 4</option>
            <option value="5">Questão 5</option>
          </select>
        </S.ContentModal>
        <S.FooterModal>
          {/* <button >close</button> */}

          <S.ButtonModal onClick={closeModal}>
            <S.TitleButtonModal>Fechar</S.TitleButtonModal>
          </S.ButtonModal>

          <S.ButtonModal onClick={() => handleAddtQuiz()}>
            <S.TitleButtonModal>Adicionar questão a lista</S.TitleButtonModal>
          </S.ButtonModal>
        </S.FooterModal>

        {arrayQuestions.length !== 0 && (
          <div className="mt-4">
            <S.TitleQuiz>Perguntas cadastradas</S.TitleQuiz>
            <Row>
              {arrayQuestions.map((value: any, index: number) => (
                <Col md={6} className={`${index > 1 && "mt-3"}`}>
                  <S.ContainerQuestion>
                    <div
                      className="exclueItem"
                      onClick={() => {
                        handleRemoveQuiz(index);
                      }}
                    >
                      X
                    </div>
                    <h2 className="title">Pergunta</h2>
                    {value.description}
                  </S.ContainerQuestion>
                </Col>
              ))}
            </Row>
            <Row>
              <Col
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="mt-4"
                md={12}
              >
                <Button onClick={() => handleSubmitQuiz()}>
                  Cadastrar Quiz
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal>

      <ModalCreateQuiz
        toggle={toggleModalCreateQuiz}
        modal={modalCreateQuiz}
        handleCreateQuizz={handleCreateQuizz}
      />

      <ModalSlide
        savedSlides={handleSavedSlides}
        lessonName={lessonName}
        module={currentModule}
        modalIsOpen={showModalSlide}
        closeModal={handleCloseModalSlide}
      />

      <ModalQuestion
        toggle={toggleModalCreateQuestion}
        modal={modalCreateQuestion}
        idQuiz={idQuiz}
        handleGetCourseData={handleGetCourseData}
        module={modulesList}
        modulePosition={modulePostion}
        quizPosition={positionQuiz}
        dataQuiz={dataQuiz}
      />
    </S.Container>
  );
};

export default AddCourse;
