//# components
// eslint-disable-next-line
import React, { useEffect , useState } from "react";

//# Components
import { Button } from "reactstrap";
import Radio from "../../components/Radio";
//import { useToast } from "../../hooks/ToastContext";

import Modal from "react-modal";

//# Styles
import { Container } from "./styled";
import * as S from "./styled";

//# services
// eslint-disable-next-line
import api from "../../services/api";


interface QuestionsProps {
  id: number;
  id_quiz: number;
  title: string;
  description: string;
  option_1: string;
  option_2: string;
  option_3: string;
  option_4: string;
  option_5: string;
  done: boolean;
}

interface Quizz {
  title: String;
  questions: Array<QuestionsProps>;
}

interface ModalProps {
  readonly toggle: () => void;
  quiz: Quizz;
  readonly modal: boolean;
  readonly showQuestions: boolean;
  readonly questions: any;
  readonly setAnswer: (arg1: any) => void;
  readonly handleFormToAnswer: (arg1: number, arg2: number) => void;
}


const ModalQuestion: React.FC<ModalProps> = ({
  toggle,
  modal,
  showQuestions,
  questions,
  quiz,
  setAnswer,
  handleFormToAnswer,
}) => {

  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    //console.log("quizzz:", quiz);
    //console.log("questions:", questions);
    //console.log("ShowQuestions:",showQuestions);

  }, [quiz, questions]);

  return (
    <div>
      <Modal
        isOpen={modal}
        ariaHideApp={false}
        onRequestClose={toggle}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          },
          content: {
            position: "absolute",
            top: "10%",
            left: "15%",
            right: "15%",
            width: "50%",
            height: "80vh",
            // bottom: '15%',
            border: "1px solid #191C22",
            background: "#191C22",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "40px 20px 20px 20px",
            inset: "auto",
          },
        }}
        contentLabel="Modal Slides"
      >
        <Container>
          <S.ModalHeader>
            <div>
              <h1>Responda o Quizz abaixo</h1>
            </div>
            <S.GroupClose onClick={toggle}>
              <span />
            </S.GroupClose>
          </S.ModalHeader>

          {isClosed===false ?
          <S.ModalContent>
            <div className="alert alert-warning d-flex align-items-center" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div>
                Cada pergunta possui apenas duas tentativas, por isso responda com atenção. 
              </div>
              <button className={"buttonWarning"} style={{marginLeft: '13%', background: 'transparent'}} onClick={()=>{
              setIsClosed(true);
                }
              }>Fechar</button>
              
            </div>
          </S.ModalContent>
          : null
          }
          <S.ModalContent>
            {quiz &&
              quiz.questions.map((question) => (
                <S.ContentQuestions>
                  <S.TitleQuiz>{question.title}</S.TitleQuiz>
                  <S.Question>{question.description}</S.Question>

                  <S.FormQuestion action="">
                    <Radio
                      name="answer"
                      //value={question.option_1}
                      value={"1"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAnswer(e.target.value)
                      }
                    >
                      {question.option_1}
                    </Radio>

                    <Radio
                      name="answer"
                      value={"2"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAnswer(e.target.value)
                      }
                    >
                      {question.option_2}
                    </Radio>

                    <Radio
                      name="answer"
                      value={"3"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAnswer(e.target.value)
                      }
                    >
                      {question.option_3}
                    </Radio>

                    <Radio
                      name="answer"
                      value={"4"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAnswer(e.target.value)
                      }
                    >
                      {question.option_4}
                    </Radio>

                    <Radio
                      name="answer"
                      value={"5"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAnswer(e.target.value)
                      }
                    >
                      {question.option_5}
                    </Radio>

                    <S.ContentFooter>
                      {question.done ? (
                        <S.MessageSuccess>
                          {/*console.log(questions)*/}
                          <S.IconAnswerSuccess size={40} color="#056F2F" />
                          Parabéns! Você já completou esta etapa!
                        </S.MessageSuccess>
                      ) : (
                        <div style={{ width: "100%" }}>
                          <S.ButtonQuestion
                            onClick={() =>
                              handleFormToAnswer(question.id, question.id_quiz)
                            }
                          >
                            RESPONDER
                          </S.ButtonQuestion>
                        </div>
                      )}
                    </S.ContentFooter>
                  </S.FormQuestion>
                </S.ContentQuestions>
              ))}
          </S.ModalContent>
          
          <Button style={{ width: "20%", marginLeft: "auto" }} onClick={toggle}>
            Finalizar
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default ModalQuestion;
