import styled from "styled-components";

import Vimeo from "@u-wave/react-vimeo";
import Radio from "@material-ui/core/Radio";

import Button from "../../components/Button";
import Modal from "../../components/Modal";

import {
  FiPlayCircle,
  FiClipboard,
  FiCheckCircle,
  FiArrowLeft,
} from "react-icons/fi";
import { RiSlideshowLine } from "react-icons/ri";

interface IProps {
  bg: string;
}

interface ContentModuleProps {
  done?: boolean;
}

export const Container = styled.div<IProps>`
  height: 100vh;
  padding-top: 100px;
  background-size: cover;
  background: linear-gradient(to right, transparent 0%, #000 75%),
    url(${(props) => props.bg}) no-repeat center center fixed;
  /* background-image: url(${(props) => props.bg});
  background: linear-gradient(to right, transparent 0%, #000 75%),
    url(${(props) => props.bg}) no-repeat no-repeat; */

  object-fit: cover;
`;

export const ContentTitle = styled.div`
  max-width: 80%;
  display: flex;
  margin: 20px auto 10px auto;
`;

export const TitleCourse = styled.h1`
  font-size: 18px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 75vh;
  margin-right: 100px;
  margin-top: 50px;
`;

export const Column = styled.div`
  width: 50%;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    border-radius: 100px;
  }
  &::-webkit-scrollbar-track {
    background-color: #191c22;
    border-radius: 100px;
    margin-left: 10px;
  }
`;

export const ContentVideo = styled.div`
  width: 715px;
  height: 400px;
`;

export const VimeoPlayer = styled(Vimeo)``;

export const ImageCourse = styled.img`
  width: 700px;
  height: 400px;
  border-radius: 5px;
`;

export const ContentModules = styled.div`
  display: flex;
`;

export const ButtonGerarCertificado = styled(Button)`
  width: 190px;
  box-shadow: rgb(0 0 0 / 60%) 0px 5px 20px;
  font-weight: 700;
`;

export const ButtonSelectLesson = styled.button`
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
`;

export const TitleLesson = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 8px;
  margin-left: 4px;
  color: #fff;
  border-radius: 5px;

  background: #2d3036;

  margin: 5px 0;

  span {
    text-transform: capitalize;
  }
`;

export const IconCheck = styled.img`
  margin-right: 10px;
`;

export const ContentModule = styled.div<ContentModuleProps>`
  background-color: #191c22;
  /* border: 1px solid ${(props) =>
    props.done ? "#a0bf69" : "transparent"}; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
`;

export const ContentOpen = styled.div`
  background-color: #191c22;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const ContentHeaderModule = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 100%;
  padding: 0;
`;

export const TitleModule = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
`;

export const ReleaseDateModule = styled.span`
  display: block;
  margin-left: auto;
  margin-right: 10px;
  b {
    margin-left: 5px;
  }
`;

export const ButtonHideAndShow = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
`;

export const IconLess = styled.img``;

export const IframeVimeo = styled.iframe`
  border-radius: 5px;
`;

export const ContentQuestions = styled.div`
  width: 700px;
  min-height: 400px;
  background: #191c22;
  border-radius: 5px;
  margin-right: 15px;
  padding: 15px;
`;

export const RadioButton = styled(Radio)``;

export const FormQuestion = styled.form`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const ContentFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

export const MessageSuccess = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #056f2f;
`;

export const IconAnswerSuccess = styled(FiCheckCircle)`
  margin: 25px 0 10px 0;
`;

export const ButtonQuestion = styled(Button)`
  width: 150px;
`;

export const TitleQuiz = styled.h1`
  font-size: 18px;
  background: #2d3036;
  margin-bottom: 8px;
  padding: 10px 5px;
  border-radius: 5px;
`;

export const Question = styled.h2`
  font-size: 16px;
  margin-bottom: 15px;
  padding: 5px;
`;

export const IconPlay = styled(FiPlayCircle)`
  margin-right: 10px;
`;

export const IconQuiz = styled(FiClipboard)`
  margin-right: 10px;
`;

export const ModalVideo = styled(Modal)`
  padding-top: 80px;
`;

export const TitleLessonModal = styled.h1`
  color: #f3f3f3;
  font-size: 17px;
  font-weight: 600;
  margin-left: 5px;
`;

export const NameAuthor = styled.h2`
  color: #b1b1b1;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
`;

export const IconBack = styled(FiArrowLeft)`
  font-size: 25px;
  color: #f3f3f3;
  margin-right: 8px;
`;

export const ButtonBack = styled.button`
  border: none;
  background: none;
  color: #f3f3f3;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 8px;
  margin-top: 20px;
`;

export const ContentDescriptions = styled.div`
  margin-bottom: 15px;
`;

export const IconSlide = styled(RiSlideshowLine)`
  margin-right: 10px;
`;

export const ButtonDownloadFile = styled(Button)`
  width: 200px;
  display: block;
  margin-left: auto;
  padding: 10px;
  margin-right: 5px;
  svg {
    margin-left: 5px;
  }
`;

export const GroupDataVideo = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 15px;
`;
