import React from "react";

import { Container, ContentFooter } from "./styles";

export default function Footer() {
  return (
    <Container>
      <ContentFooter>
        <p>Las do Brasil - Plataforma EAD - Todos os direitos reservados</p>
      </ContentFooter>
    </Container>
  );
}
