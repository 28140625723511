import styled from "styled-components";

import { FiCheckCircle } from "react-icons/fi";
// eslint-disable-next-line
import { BlueMain, GreenMain, BlackMain } from "../../styles/colors";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 100vh;
  padding-top: 85px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  height: calc(85vh - 85px);
  .title-content {
    max-width: 25vw;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 5%;

  h1 {
    font-size: 40px !important;
    font-weight: bold;
    color: #fff;
  }

  p {
    font-size: 20px !important;
    font-weight: 500;
    color: #fff;
    margin-top: 15px;
    padding-right: 1.5rem;
  }

  img {
    width: 585px;
    height: 390px;
  }

  iframe {
    border-radius: 5px;
  }
`;

export const CourseSlide = styled(Link)`
  border: 1px dashed ${BlackMain};
  background: ${BlackMain};
  border-radius: 25px;
  padding: 8px 20px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: max-content !important;
  min-width: 200px !important;
  cursor: pointer;
  text-decoration: none !important;
  transition: border 0.2s ease 0s;
  h2 {
    margin: 0px;
  }
  &:hover {
    border-color: ${BlueMain};
  }
`;

export const NameCourse = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: #f3f3f3;
`;

export const Icon = styled(FiCheckCircle)`
  font-size: 15px;
  /* font-weight: 600; */
  color: green;
  margin-left: 8px;
`;

export const WrapperSlide = styled.div`
  padding: 2rem 0rem;
  height: 13vh;
  max-width: 80%;
  margin: 0 auto;

  .slick-initialized .slick-slide {
    max-width: 250px;
  }

  .slick-next {
    &::before,
    &::after {
      content: "";
      width: 16px;
      height: 4px;
      background: #256bcd;
      display: block;
      opacity: 1;
      border-radius: 15px;
    }
    &::before {
      transform: rotate(45deg) translate(2px, 0px);
    }
    &::after {
      transform: rotate(-45deg) translate(-4px, 6px);
    }
  }

  .slick-prev {
    &::before,
    &::after {
      content: "";
      width: 16px;
      height: 4px;
      background: #256bcd;
      display: block;
      opacity: 1;
      border-radius: 15px;
    }
    &::before {
      transform: rotate(-45deg) translate(-2px, -7px);
    }
    &::after {
      transform: rotate(45deg) translate(-4px, 6px);
    }
  }

  .slick-dots {
    margin-top: 2rem;
    .slick-active {
      button {
        &::before {
          color: #fff !important;
        }
      }
    }
    button {
      &::before {
        font-size: 8px;
        color: grey !important;
      }
    }
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
`;

export const WrapperProgress = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 15px;
`;
