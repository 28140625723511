import styled from "styled-components";

import { FiMenu } from "react-icons/fi";
import { BlackMain } from "../../styles/colors";

interface IProps {
  visible: boolean;
}

export const Container = styled.div`
  background: ${BlackMain};
  height: 85px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
`;

export const ContentHeader = styled.div`
  max-width: 80%;
  height: 85px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div``;

export const Menu = styled.div`
  nav {
    display: flex;
    flex-direction: column;
    .help {
      span {
        margin-left: 20px;
      }
    }
    a:first-child {
      padding-top: 16px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    a:last-child {
      padding-bottom: 16px;
    }
    a {
      color: #e1e1e6;
      font-size: 16px;
      padding: 12px 24px;
      text-decoration: none;
      font-weight: 600;
      transition: background 0.2s ease 0s;
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
      span {
        margin-left: 24px;
      }
    }
  }
`;

export const Account = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AccountInfo = styled.div`
  text-align: end;

  h1 {
    font-size: 15.5px;
    font-weight: bold;
    color: #fff;
  }

  h2 {
    font-size: 11.5px;
    color: #fff;
    font-weight: 600;
  }

  img {
    margin-left: 10px;
  }
`;

export const ContentMenu = styled.div`
  position: relative;
`;

export const ButtonMenu = styled.button`
  border: 0;
  background: none;
  color: #f3f3f3;
  font-size: 15px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const IconMenu = styled(FiMenu)`
  font-size: 25px;
  color: #f3f3f3;
  margin-right: 5px; ;
`;

export const MenuDrop = styled.div<IProps>`
  position: absolute;
  width: 240px;
  left: 50%;
  transform: translateX(-85%);
  top: calc(100% + 20px);
  z-index: 999;
  background: ${BlackMain};
  box-shadow: rgb(0 0 0 / 60%) 0px 5px 20px;
  border-radius: 5px;
  /* padding: 15px; */
  display: ${(props) => (props.visible ? "block" : "none")};

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 19px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: #0000 #0000 #202024;
  }
`;

export const MenuLink = styled.h1`
  font-size: 16px;
  margin-top: 8px;
`;
