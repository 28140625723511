import styled from "styled-components";
import { BlackMain, BlueMain, PrimaryColorHover } from "../../styles/colors";

type FileScreenContentProps = {
  show?: boolean;
};

export const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${BlackMain};
  border-radius: 15px;
  padding: 64px 32px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  h1 {
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
  }
`;

export const GroupClose = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #808080;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 25px;
  top: 35px;

  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 0.6;
  }

  span {
    width: 20px;
    height: 3px;
    background: white;
    border-radius: 5px;
    display: block;
    transform: rotate(45deg);

    &::before {
      content: "";
      background: white;
      width: 20px;
      height: 3px;
      display: block;
      border-radius: 5px;
      transform: rotate(-90deg);
    }
  }
`;

export const WrapperSlide = styled.div`
  padding: 0px 2rem;

  .slick-next {
    transition: 0.2s ease-in-out;
    transform: translateX(10px);
    &:hover {
      transform: translateX(15px);
      &::before,
      &::after {
        background: ${PrimaryColorHover};
      }
    }
    &::before,
    &::after {
      content: "";
      width: 16px;
      height: 4px;
      background: ${BlueMain};
      display: block;
      opacity: 1;
      border-radius: 15px;
    }
    &::before {
      transform: rotate(45deg) translate(8px, 1px);
    }
    &::after {
      transform: rotate(-45deg) translate(6px, 2px);
    }
  }

  .slick-prev {
    transform: translateX(-3px);
    transition: 0.2s ease-in-out;
    &:hover {
      transform: translateX(-10px);
      &::before,
      &::after {
        background: ${PrimaryColorHover};
      }
    }
    &::before,
    &::after {
      content: "";
      width: 16px;
      height: 4px;
      background: ${BlueMain};
      display: block;
      opacity: 1;
      border-radius: 15px;
    }
    &::before {
      transform: rotate(-45deg) translate(-9px, 1px);
    }
    &::after {
      transform: rotate(45deg) translate(-6px, 2px);
    }
  }

  .slick-dots {
    bottom: -40px;
    .slick-active {
      button {
        &::before {
          color: #fff !important;
        }
      }
    }
    button {
      &::before {
        font-size: 8px;
        color: grey !important;
      }
    }
  }
`;

export const PictureSlide = styled.img`
  width: 100%;
  height: 500px;
  max-height: 500px;
  object-fit: cover;
`;

export const GroupFile = styled.div`
  position: relative;
`;

export const ExpandFile = styled.div`
  position: absolute;
  z-index: 999;
  right: 20px;
  bottom: 15px;
  background: ${BlueMain};
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background: ${PrimaryColorHover};
  }
  svg {
    margin-right: 10px;
  }
`;

export const FileScreen = styled.img`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`;

export const CloseFileScreen = styled.div`
  background: ${BlueMain};
  position: absolute;
  right: 20px;
  bottom: 15px;
  z-index: 9999;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  padding: 10px 0px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: ${PrimaryColorHover};
  }
`;

export const FileScreenContent = styled.div<FileScreenContentProps>`
  transition: opacity 1s ease-in-out;
  opacity: ${(props) => (props.show ? "1" : "0")};
`;
