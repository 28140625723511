import styled from "styled-components";
import { BlackMain, GreyLight, BlueMain, RedMain } from "../../styles/colors";

interface ButtonProps {
  delete?: boolean;
}

export const ModalDelete = styled.div``;

export const Button = styled.div<ButtonProps>`
  background: ${(props) => (props.delete ? RedMain : BlueMain)};
  padding: 15px 0px;
  border-radius: 5px;
  border-width: 0;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  transition: background 0.2s ease-in-out;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => (props.delete ? "#e55050" : "#187ea4")};
  }
`;

export const ContentModal = styled.div`
  background: ${BlackMain};
  border-radius: 10px;
  padding: 32px;

  h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    color: ${GreyLight};
    line-height: 24px;
    svg {
      margin-right: 8px;
    }
  }
`;

export const GroupButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 3.5rem;
`;
