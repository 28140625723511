import styled from "styled-components";

export const Container = styled.div`
    background:#191c22;

    p {
        font-weight:500 !important;
        margin-bottom:0px;
    }
  
`