import styled from "styled-components";

export const Container = styled.div`
  h2 {
    font-size: 14px;
    margin: 0px;
    color: white;
    font-weight: 600;
  }
`;
