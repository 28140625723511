// eslint-disable-next-line
import React, { ChangeEvent } from 'react';

import { RadioWrapper, Label, Input, Mark } from './styles';

interface RadioProps {
    children: string,
    value: string,
    name?: string,
    onChange: any
}

const Radio: React.FC<RadioProps> = ({ children, name, value, onChange, ...rest }) => {

    //console.log(rest);
    return (
        <RadioWrapper>
            <Label>
                <Input name={name} value={value} onChange={onChange} type="radio" />
                <Mark />
                {children}
            </Label>
        </RadioWrapper>
    );
};

export default Radio;