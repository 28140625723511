import styled from "styled-components";

import Button from "../../components/Button";
import Input from "../../components/Input";
import { BlackMain, BlackSecondary, BlueMain } from "../../styles/colors";

interface DepartmentSelectProps {
  onClick?: any;
}

interface DepartmentOptionsProps {
  show: boolean;
}

export const Container = styled.div`
  padding-top: 85px;
  .footer {
    padding: 40px 0px;
  }
`;

export const Content = styled.div`
  max-width: 80%;
  margin: 40px auto 0;
  border-radius: 5px;
  background: ${BlackMain};

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;

  input {
    margin-bottom: 15px;
  }

  form {
    h2 {
      margin-bottom: 10px;
    }
  }

  select {
    width: 400px;
    height: 60px;
    background: ${BlackSecondary};
    border-radius: 5px;
    margin: 8px 0;
    font-size: 12px;
    color: #fff;
    padding: 20px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
  }

  select::-ms-expand {
    display: none;
  }

  span {
    color: red;
  }
`;

export const InputText = styled(Input)`
  width: 400px;
`;

export const ButtonSubmit = styled(Button)`
  width: 400px;
  margin-top: 20px;
`;

export const DepartmentSelect = styled.div<DepartmentSelectProps>`
  width: 400px;
  min-height: 60px;
  display: flex;
  align-items: center;
  background: ${BlackSecondary};
  border-radius: 5px;
  margin: 10px 0px;
  font-size: 12px;
  color: #696c73;
  position: relative;
`;

export const DepartmentOptions = styled.div<DepartmentOptionsProps>`
  position: absolute;
  background: ${BlackSecondary};
  transform: translateY(-5px);
  z-index: 999;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  overflow-y: auto;
  transition: 0.2s ease-in-out;
  height: ${(props) => (props.show ? "160px" : "0px")};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  span {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #fff;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const BadgeDepartment = styled.div`
  min-width: 100px;
  max-width: max-content;
  background: ${BlueMain};
  border-radius: 32px;
  padding: 5px 30px 5px 15px;
  color: white;
  display: flex;
  position: relative;

  span {
    width: 12px;
    height: 2px;
    background: white;
    border-radius: 5px;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    &:hover {
      background: #fa8072;
      &::before {
        background: #fa8072;
      }
    }

    &::before {
      content: "";
      background: white;
      width: 12px;
      height: 2px;
      display: block;
      border-radius: 5px;
      transform: rotate(-90deg);
    }
  }
`;

export const DepartmentSelectButton = styled.div`
  width: 100%;
  padding: 20px;
  cursor: pointer;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;

  span {
    color: #fff;
  }
`;

export const GroupInput = styled.div`
  margin-top: 15px;
`;
