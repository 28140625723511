import React, { createContext, useCallback, useState, useContext } from 'react';
// eslint-disable-next-line
import { ResponsiveEmbed } from 'react-bootstrap';

import api from '../services/api';
// eslint-disable-next-line
import { useToast } from './ToastContext';

interface User {
    id: number,
    name: string,
    email: string,
    phone: string,
    cpf: string,
    id_permission: number
}

interface AuthState {
    token: string,
    user: User,
    permission?: any
}

interface SignInCredentials {
    cpf: string,
    password: string;
}

interface AuthContextData {
    user: User,
    permission: any,
    signIn(credentials: SignInCredentials): Promise<void>,
    signOut(): void
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {

    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@Las:token');
        const user = localStorage.getItem('@Las:user');
        // eslint-disable-next-line
        const permission = localStorage.getItem('@Las:permission');

        if (token && user) {
            api.defaults.headers.Authorization = `Bearer ${token}`;

            api.interceptors.response.use(
                (res) => res,

                async (err) => {
                    console.log("Interceptor response error");

                    console.log(err.response.status);

                    if (err.response.status === 401) {

                        localStorage.removeItem('@Las:token');
                        localStorage.removeItem('@Las:user');
                        localStorage.removeItem('@Las:permission');

                        setData({} as AuthState);
                    }

                    return Promise.reject(err);
                },
            );

            return { token, user: JSON.parse(user) };
        }

        return {} as AuthState;
    });

    const signIn = useCallback(async ({ cpf, password }) => {

        const response = await api.post('auth', {
            cpf,
            password
        });
        //console.log("O que tem aqui::::"+response.data);
        const { token, user, permission } = response.data;

        localStorage.setItem('@Las:token', token);
        localStorage.setItem('@Las:user', JSON.stringify(user));
        localStorage.setItem('@Las:permission', permission);

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setData({ token, user, permission });

    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('@Las:token');
        localStorage.removeItem('@Las:user');
        localStorage.removeItem('@Las:permission');

        setData({} as AuthState);
    }, []);

    return (
        <AuthContext.Provider value={{ user: data.user, permission: data.permission, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
}
