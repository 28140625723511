import styled from "styled-components";

import { FiCheckCircle } from "react-icons/fi";
import Button from "../../components/Button";

export const Container = styled.div`
  background: #191c22;

  p {
    font-weight: 500 !important;
    margin-bottom: 0px;
  }

  .buttonWarning {
    border-radius: 10px;
    width: 60px;
  }

  .header-quiz {
    font-size: 2rem;
  }

  .brn-update-quiz {
    justify-content: flex-end;
    display: flex;
  }

  .cardFormNewQuestion {
    background: rgb(255 255 255 / 3%);
    border-radius: 5px;
    box-shadow: 0px 0px 8px 2px #00000026;
    position: relative;
  }

  .cardQuestionAdd {
    background: rgb(255 255 255 / 13%);
    border-radius: 5px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    height: 130px;
    padding: 5px;
    line-height: 20px;
    text-align: center;
  }

  .cardQuestion {
    background: rgb(255 255 255 / 13%);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    height: 130px;
    padding: 10px;
    line-height: 20px;

    .btn-card {
      height: 25px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .closeButton {
    position: absolute;
    right: 50px;
    top: 15px;
    cursor: pointer;
  }
`;

export const ContentQuestions = styled.div`
  width: 100%;
  background: #191c22;
  border-radius: 5px;
  margin-right: 15px;
  padding: 15px;
`;

export const TitleQuiz = styled.h1`
  font-size: 18px;
  background: #2d3036;
  margin-bottom: 8px;
  padding: 10px 15px;
  border-radius: 5px;
`;

export const Question = styled.h2`
  font-size: 16px;
  margin-bottom: 15px;
  padding: 5px;
`;

export const FormQuestion = styled.form`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const ContentFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const MessageSuccess = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #056f2f;
`;

export const IconAnswerSuccess = styled(FiCheckCircle)`
  margin: 25px 0 10px 0;
`;

export const ButtonQuestion = styled(Button)`
  width: 100%;
  height: 41px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  h1 {
    font-size: 22px;
  }
`;

export const GroupClose = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #808080;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: -10px;

  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 0.6;
  }

  span {
    width: 20px;
    height: 3px;
    background: white;
    border-radius: 5px;
    display: block;
    transform: rotate(45deg);

    &::before {
      content: "";
      background: white;
      width: 20px;
      height: 3px;
      display: block;
      border-radius: 5px;
      transform: rotate(-90deg);
    }
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
