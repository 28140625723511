import React from "react";

import * as S from "./styles";

interface ModalProps {
  modalShow: boolean;
  fill: string;
  bg: string;
}

const Modal: React.FC<ModalProps> = ({ modalShow, fill, bg, children }) => {
  return (
    <S.Container>
      {modalShow && (
        <>
          <S.Backdrop fill={fill} />
          <S.Wrapper>
            <S.Content bg={bg}>{children}</S.Content>
          </S.Wrapper>
        </>
      )}
    </S.Container>
  );
};

export default Modal;
