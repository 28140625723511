import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

import { Container, Content, Columns, ButtonSubmit, InputText } from "./styles";
import * as S from "./styles";

import api from "../../services/api";

import { useToast } from "../../hooks/ToastContext";
import { useAuth } from "../../hooks/AuthContext";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Label from "../../components/Label";
import { Spinner } from "reactstrap";
import { cpfMask, phoneMask } from "../../utils";

interface DataCourseProps {
  id_course: number;
  course_name: string;
  id_departaments: number[];
}

/*interface DataPermissionProps {
  id: number;
  name: string;
}*/

interface ParamsProps {
  id: string;
}

interface IDepartments {
  id: number;
  name: string;
}

interface UserDataProps {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  password?: string;
  departaments: IDepartments[];
  courses: DataCourseProps[];
}

const initialDataUser = {
  name: "",
  email: "",
  phone: "",
  cpf: "",
  password: "",
  departaments: [],
  courses: [],
};

export default function Profile() {
  const [userData, setUserData] = useState<UserDataProps>(initialDataUser);
  const [departments, setDepartments] = useState<IDepartments[]>([]);
  const [showOptionsDepartment, setShowOptionsDepartment] = useState<boolean>(
    false
  );
  const [showOptionsCourses, setShowOptionsCourses] = useState<boolean>(false);
  const [permission, setPermission] = useState<number>(0);
  const [dataCourse, setDataCourse] = useState<DataCourseProps[]>([]);
  const [userCourse, setUserCourse] = useState<DataCourseProps[]>([]);
  const selectCourses = useRef(null);
  const selectDepartment = useRef(null);
  const { id } = useParams<ParamsProps>();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    // Lista todos os departamentos
    api.get("v1/departament").then((response) => {
      setDepartments(response.data.data);
    });

    // Lista todos os dados do usuário selecionado
    api.get(`v1/user/${id}`).then((response) => {
      setUserData({
        ...response.data,
        phone: phoneMask(response.data?.phone),
        cpf: cpfMask(response.data?.cpf),
      });
      setPermission(response.data.id_permission);
    });
  }, [id]);

  

  // Chama função que lista os cursos do usuário selecionado
  useEffect(() => {
    // Lista todos os cursos do usuário selecionado
    async function getCoursesUser() {
    const response = await api.get(`v1/get-courses-user/${id}`);

    const formattedCourses: DataCourseProps[] = [];

    response?.data?.courses.forEach((course: any) => {
      const idsDepartments = course?.depataments.map(({ id }: any) => id);

      formattedCourses.push({
        id_course: course?.id_course,
        course_name: course?.name,
        id_departaments: idsDepartments,
      });
    });

    setUserCourse(formattedCourses);
  }
    getCoursesUser();
  }, [id]);

  

  // Lista os cursos disponiveis baseado no departamento selecionado.
  useEffect(() => {
    const departmentsIds: number[] = [];

    userData?.departaments.map((department) => {
      return departmentsIds.push(department.id);
    });

    const getCoursesByDepartments = async (departmentsIds: number[]) => {
      if (departmentsIds.length >= 1) {
        await api
          .get(`v1/get-courses-departament/${departmentsIds}`)
          .then((response) => {
            const availableCourses = response.data;
  
            // Filtra apenas uma vez o curso cadastrado em varios departamentos
            // const courseInMultipleDepartments = availableCourses?.filter(
            //   (course: any, i: any, arr: any) =>
            //     arr.findIndex((t: any) => t?.id === course?.id) === i
            // );
  
            // Lista apenas os cursos que não estão selecionados
            const validCourses: DataCourseProps[] = availableCourses?.filter(
              (course: DataCourseProps) => {
                if (
                  !userCourse.find(
                    (courseUser) => courseUser.id_course === course.id_course
                  )
                ) {
                  return course;
                }
                else return false;
              }
            );
  
            setDataCourse(validCourses);
          });
      } else {
        // se não tiver nenhum departamento selecionado ele limpa as opções de cursos
        // setDataCourse([]);
        // setUserCourse([]);
      }
    }

    getCoursesByDepartments(departmentsIds);
  }, [userData.departaments, userCourse]);

  // Lista apenas os departamentos que não estão selecionados
  useEffect(() => {
    if (userData?.departaments.length >= 1) {
      // eslint-disable-next-line
      const differentDepartments = departments.filter((department) => {
        if (
          !userData.departaments.find(
            (deptUser) => deptUser.id === department.id
          )
        ) {
          return department;
        }
  
      });

      setDepartments(differentDepartments);
    }
    // eslint-disable-next-line
  }, [userData.departaments]);

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showOptionsDepartment]);

  function handleClickOutside(event: any) {
    if (
      selectDepartment?.current &&
      // @ts-ignore
      !selectDepartment?.current?.contains(event.target)
    ) {
      setShowOptionsDepartment(false);
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousedown", handleClickOutsideCourses);

      return () => {
        window.removeEventListener("mousedown", handleClickOutsideCourses);
      };
    }
  }, [showOptionsCourses]);

  function handleClickOutsideCourses(event: any) {
    if (
      selectCourses?.current &&
      // @ts-ignore
      !selectCourses?.current?.contains(event.target)
    ) {
      setShowOptionsCourses(false);
    }
  }

  const handleAddDepartment = (departmentAdd: IDepartments) => {
    setShowOptionsDepartment(false);
    const auxDepartmentsUser = [...userData.departaments];
    auxDepartmentsUser.push(departmentAdd);

    setUserData({
      ...userData,
      departaments: auxDepartmentsUser,
    });
  };

  const handleRemoveDepartment = (department: IDepartments) => {
    setShowOptionsDepartment(false);
    const auxDepartmentsUser = [...userData.departaments];
    const currentDepartments = [...departments];
    const departmentPosition = auxDepartmentsUser.findIndex(
      (dep) => dep.id === department.id
    );

    const departmentDeleted = auxDepartmentsUser.find(
      (dep) => dep.id === department.id
    );

    // console.log("userCourses:", userCourse);

    // const availableCourses = userCourse.filter((course) =>
    //   course?.id_departaments.find((id) => id !== department.id)
    // );

    // console.log("cursos disponiveis:", availableCourses);

    if (departmentDeleted) currentDepartments.push(departmentDeleted);

    auxDepartmentsUser.splice(departmentPosition, 1);

    setUserData({
      ...userData,
      departaments: auxDepartmentsUser,
    });

    setDepartments(currentDepartments);
    // setUserCourse(availableCourses);
  };

  const handleAddCourse = (courseAdd: DataCourseProps) => {
    setShowOptionsCourses(false);
    const courseAlreadySelected = userCourse.find(
      (course) => course.id_course === courseAdd.id_course
    );
    if (courseAlreadySelected) return;

    const auxCourses = [...userCourse];
    auxCourses.push(courseAdd);

    setUserData({
      ...userData,
      courses: auxCourses,
    });

    setUserCourse(auxCourses);
  };

  const handleRemoveCourse = (course: DataCourseProps) => {
    setShowOptionsCourses(false);
    const auxCourse = [...userCourse];

    const currentCourseOption = [...dataCourse];
    const coursePosition = auxCourse.findIndex(
      (cour) => cour.id_course === course.id_course
    );
    const courseDeleted = auxCourse.find(
      (cour) => cour.id_course === course.id_course
    );

    // @ts-ignore
    currentCourseOption.push(courseDeleted);

    auxCourse.splice(coursePosition, 1);

    setUserCourse(auxCourse);

    // setDataCourse(currentCourseOption);
  };

  async function handleSubmit() {
    const departmentsIds: number[] = [];
    const coursesIds: number[] = [];
    const { cpf, name, email, phone, password, departaments } = userData;
    setIsSubmitting(true);

    departaments.map((department) => {
      return departmentsIds.push(department.id);
    });

    userCourse.map((course) => {
      return coursesIds.push(course.id_course);
    });

    if (user.id_permission === 1) {
      const body = {
        name: name,
        id_permission: permission,
        cpf: cpf,
        email: email,
        phone: phone,
        id_departament: departmentsIds.length >= 1 && departmentsIds,
        id_course: coursesIds.length >= 1 && coursesIds,
        password: password,
      };

      api
        .put(`v1/user/${id}`, body)
        .then((response) => {
          if (response.status === 200) {
            setIsSubmitting(false);
            addToast({
              type: "success",
              title: "Usuário atualizado com sucesso!",
            });
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          addToast({
            type: "error",
            title:
              "Ocorreu um erro ao atualizar o usuário, verifique os dados.",
          });
        });
    }

    if (user.id_permission === 2) {
      const body = {
        phone: phone,
        password: password,
      };

      api.put(`v1/user/${id}`, body).then((response) => {
        if (response.status === 200) {
          setIsSubmitting(false);
          addToast({
            type: "success",
            title: "Dados alterados com sucesso!",
          });
        } else {
          setIsSubmitting(false);
          addToast({
            type: "error",
            title: "Erro! Favor tentar novamente!",
          });
        }
      });
    }
  }

  // Manipula os dados do formulario
  const handleUserDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [event.target.name]: cpfMask(event.target.value),
    });
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [event.target.name]: phoneMask(event.target.value),
    });
  };

  const isAdmin = user.id_permission === 1;

  return (
    <Container>
      <Header />
      <Content>
        <Columns>
          <form>
            <Label>Nome:</Label>
            <InputText
              type="text"
              name="name"
              placeholder="Digite seu nome"
              value={userData?.name}
              onChange={(e) => handleUserDataChange(e)}
              disabled={!isAdmin}
            />

            <Label>E-mail:</Label>
            <InputText
              type="text"
              name="email"
              placeholder="Digite seu e-mail"
              value={userData?.email}
              onChange={(e) => handleUserDataChange(e)}
              disabled={!isAdmin}
            />

            <Label>Telefone:</Label>
            <InputText
              type="text"
              name="phone"
              placeholder="Digite seu telefone"
              value={userData?.phone}
              onChange={(e) => handleChangePhone(e)}
            />

            <Label>Cpf:</Label>
            <InputText
              type="text"
              name="cpf"
              placeholder="Digite seu cpf"
              value={userData?.cpf}
              onChange={(e) => handleChangeCPF(e)}
              disabled={!isAdmin}
            />

            <Label>Senha:</Label>
            <InputText
              type="password"
              name="password"
              placeholder="Digite sua senha"
              value={userData?.password}
              onChange={(e) => handleUserDataChange(e)}
            />
          </form>
        </Columns>
        <Columns>
          {user.id_permission === 1 && (
            <>
              <Label>Departamento:</Label>
              <div ref={selectDepartment}>
                <S.DepartmentSelect>
                  <S.DepartmentSelectButton
                    onClick={() =>
                      departments.length >= 1 && setShowOptionsDepartment(true)
                    }
                    show={showOptionsDepartment}
                  >
                    {userData?.departaments.map((department) => (
                      <S.BadgeDepartment key={department.id}>
                        {department.name}
                        {userData?.departaments.length > 1 && (
                          <span
                            onClick={() => handleRemoveDepartment(department)}
                          />
                        )}
                      </S.BadgeDepartment>
                    ))}
                    {userData?.departaments.length <= 0 && (
                      <span>Selecione o departamento</span>
                    )}
                    <FaChevronDown size={14} className="arrow-down" />
                  </S.DepartmentSelectButton>
                  <S.DepartmentOptions show={showOptionsDepartment}>
                    {departments.map((department) => (
                      <span
                        onClick={() => handleAddDepartment(department)}
                        key={department.id}
                      >
                        {department.name}
                      </span>
                    ))}
                  </S.DepartmentOptions>
                </S.DepartmentSelect>
              </div>
            </>
          )}

          {user.id_permission === 1 && (
            <>
              <Label>Curso:</Label>
              <div ref={selectCourses}>
                <S.DepartmentSelect>
                  <S.DepartmentSelectButton
                    show={showOptionsCourses}
                    onClick={() =>
                      dataCourse.length >= 1 && setShowOptionsCourses(true)
                    }
                  >
                    {userCourse.map((course) => (
                      <S.BadgeDepartment key={course.id_course}>
                        {course.course_name}
                        <span onClick={() => handleRemoveCourse(course)} />
                      </S.BadgeDepartment>
                    ))}
                    {!userData?.departaments.length && (
                      <span>Selecione pelo menos 1 departamento</span>
                    )}

                    {userData?.departaments.length >= 1 &&
                      !userCourse?.length && <span>Selecione o curso</span>}
                    <FaChevronDown size={14} className="arrow-down" />
                  </S.DepartmentSelectButton>
                  <S.DepartmentOptions show={showOptionsCourses}>
                    {dataCourse.map((course) => (
                      <span
                        onClick={() => handleAddCourse(course)}
                        key={course.id_course}
                      >
                        {course.course_name}
                      </span>
                    ))}
                  </S.DepartmentOptions>
                </S.DepartmentSelect>
              </div>
            </>
          )}

          {user.id_permission !== 1 && (
            <>
              <div>
                <Label>Departamento:</Label>
                <div ref={selectDepartment}>
                  <S.DepartmentSelect disabled={!isAdmin}>
                    <S.DepartmentSelectButton cursorInitial>
                      {userData?.departaments.map((department) => (
                        <S.BadgeDepartment key={department.id}>
                          {department.name}
                        </S.BadgeDepartment>
                      ))}
                    </S.DepartmentSelectButton>
                  </S.DepartmentSelect>
                </div>
              </div>

              <div>
                <Label>Curso:</Label>
                <div ref={selectCourses}>
                  <S.DepartmentSelect disabled={!isAdmin}>
                    <S.DepartmentSelectButton cursorInitial>
                      {userCourse.map((course) => (
                        <S.BadgeDepartment key={course.id_course}>
                          {course.course_name}
                        </S.BadgeDepartment>
                      ))}
                    </S.DepartmentSelectButton>
                  </S.DepartmentSelect>
                </div>
              </div>
            </>
          )}

          <ButtonSubmit onClick={handleSubmit}>
            {isSubmitting ? <Spinner size="sm" /> : "Salvar"}
          </ButtonSubmit>
        </Columns>
      </Content>
      <section className="footer">
        <Footer />
      </section>
    </Container>
  );
}
