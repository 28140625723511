import React, { InputHTMLAttributes } from "react";

import { Container, InputField } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  type: string;
}

const Input: React.FC<InputProps> = ({ ...rest }) => {
  return (
    <Container>
      <InputField {...rest} />
    </Container>
  );
};

export default Input;
