import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";

import api from "../../services/api";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import icon_search from "../../images/icon_search.svg";

interface DataUsers {
  id: number;
  name: string;
  email: string;
  id_permission: number;
  active: number;
}

const Users: React.FC = () => {
  const [dataUser, setDataUser] = useState<DataUsers[]>([]);
  //const [search, setSearch] = useState<string>();

  useEffect(() => {
    async function getUsers() {
      const response = await api.get("v1/user");
      setDataUser(response.data.data);
    }

    getUsers();
  }, []);

  return (
    <S.Container>
      <Header />
      <S.Content>
        <S.ContentFilters>
          <S.InputSearch type="text" placeholder="Buscar pelo nome" />
          <Link to="/register-user">
            <S.ButtonRegister>Cadastrar usuário</S.ButtonRegister>
          </Link>
        </S.ContentFilters>

        <S.ContentListHeader>
          <S.ColumnId>
            <h1>ID</h1>
          </S.ColumnId>
          <S.ColumnName>
            <h1>Nome</h1>
          </S.ColumnName>
          <S.ColumnEmail>
            <h1>E-mail</h1>
          </S.ColumnEmail>
          {/* <ColumnLevel>
                        <h1>Nível de acesso</h1>
                    </ColumnLevel> */}
          <S.ColumnStatus>
            <h1>Status</h1>
          </S.ColumnStatus>
          <S.ColumnActions>
            <h1>Ações</h1>
          </S.ColumnActions>
        </S.ContentListHeader>

        {dataUser.map((user) => (
          <S.ContentList key={user.id}>
            <S.ColumnId>
              <h1>{user.id}</h1>
            </S.ColumnId>
            <S.ColumnName>
              <h1>{user.name}</h1>
            </S.ColumnName>
            <S.ColumnEmail>
              <h1>{user.email}</h1>
            </S.ColumnEmail>
            {/* <ColumnLevel>
                            <h1>{user.permission}</h1>
                        </ColumnLevel> */}
            <S.ColumnStatus>
              {user.id_permission === 2 ? (
                <h1>Usuário</h1>
              ) : (
                <h1>Administrador</h1>
              )}
            </S.ColumnStatus>
            <S.ColumnActions>
              <Link to={`/profile/${String(user.id)}`}>
                <img src={icon_search} alt="" />
              </Link>
            </S.ColumnActions>
          </S.ContentList>
        ))}
      </S.Content>
      <Footer />
    </S.Container>
  );
};

export default Users;
