import styled from "styled-components";

import Button from "../../components/Button";
import Input from "../../components/Input";
import { BlueMain, BlackMain } from "../../styles/colors";

import bg from "../../images/bg.svg";

export const ContainerBg = styled.div`
  min-height: 100vh;
  background: url(${bg}) no-repeat;
  background-size: cover;
  background-color: rgb(18, 18, 20);

  display: flex;
  justify-content: center;
  align-items: center;

  .container-text {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;

    img {
      width: 20%;
    }

    .title {
      color: rgb(225, 225, 230);
      margin-top: 25px;

      h1 {
        font-size: 40px;
        line-height: 55px;
        font-weight: 600;

        @media (max-width: 996px) {
          margin-top: 30px;
          font-size: 40px;
          line-height: 60px;
        }
      }
    }
  }
`;

export const ContainerLogin = styled.div`
  padding: 65px;
  background-color: ${BlackMain};
  border-radius: 5px;

  p {
    color: ${BlueMain};
    font-weight: 700;
    margin-top: 10px;
    font-size: 13px;
    cursor: pointer;
  }

  button {
    width: 100%;
  }

  @media (max-width: 996px) {
    padding: 35px;
    margin-top: 20px;
  }
`;

export const Title = styled.h1`
  color: #eee;
`;

export const InputLogin = styled(Input)`
  width: 100%;
  margin-bottom: 10px;
`;

export const ColumnLoginText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  h1 {
    font-size: 40px !important;
    font-weight: bold;
    color: #fff;
  }
`;

export const ButtonSubmit = styled(Button)`
    width 100%;
`;
