import React, { useState } from "react";
import { Modal } from "reactstrap";
import { HiOutlineViewGrid } from "react-icons/hi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "./styles";

interface SlidesProps {
  id: number;
  id_slide: number;
  file: string;
}

interface ModalSlideProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  slides: SlidesProps[];
}

interface ExpandFileProps {
  show: boolean;
  url: string;
}

const settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ModalCourseSlide = ({
  closeModal,
  modalIsOpen,
  slides,
}: ModalSlideProps) => {
  const [expandFile, setExpandFile] = useState<ExpandFileProps>(
    {} as ExpandFileProps
  );

  const handleExpandFile = (file: SlidesProps) => {
    setExpandFile({
      show: true,
      //url: `https://userbox.com.br/ead_api/storage/app/modules/slides/${file.file}`,
      url: `https://las.app.br/ead_api/storage/app/modules/slides/${file.file}`,
    });
  };

  return (
    <>
      {expandFile?.url && expandFile.show && (
        <S.FileScreenContent show={expandFile.show}>
          <S.FileScreen src={expandFile?.url} alt="slide" />
          <S.CloseFileScreen
            onClick={() => setExpandFile({ show: false, url: "" })}
          >
            Voltar
          </S.CloseFileScreen>
        </S.FileScreenContent>
      )}
      <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        size="xl"
        centered={true}
        className="modalslide"
      >
        <S.ModalContent>
          <S.WrapperSlide>
            <Slider {...settings}>
              {slides &&
                slides.map((file: SlidesProps) => (
                  <S.GroupFile key={file.id}>
                    <S.PictureSlide
                      key={file.id_slide}
                      //src={`https://userbox.com.br/ead_api/storage/app/modules/slides/${file.file}`}
                      src={`https://las.app.br/ead_api/storage/app/modules/slides/${file.file}`}
                      alt="image slide"
                    />
                    <S.ExpandFile onClick={() => handleExpandFile(file)}>
                      <HiOutlineViewGrid color="#fff" size={20} />
                      <span>Visualizar em tela cheia</span>
                    </S.ExpandFile>
                  </S.GroupFile>
                ))}
            </Slider>
          </S.WrapperSlide>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default ModalCourseSlide;
