import React, { useState, FormEvent } from "react";
import { Link } from "react-router-dom";
//import axios from "axios";

import { ContainerBg, Container, Content, ContainerReset, InputText } from "./styles";
import Button from "../../components/Button";

import Logo from "../../assets/logo_las.svg";

import api from "../../services/api";

//import Footer from "../../components/Footer";
import Label from "../../components/Label";
import { Spinner } from "reactstrap";

import { useToast } from "../../hooks/ToastContext";


const ResetPassword: React.FC = () =>  {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [error, setError] = useState(false);

  //const enviaEmail = teste(email);
  const { addToast } = useToast();
  //return;
  //const  addToast = (data: any) => console.log(data);
  // eslint-disable-next-line
  const [modalShow, setModalShow] = useState<boolean>(false);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      if (email === "") {
        addToast({
          type: "error",
          title: "Campos obrigatórios",
          description: "O campo E-mail é obrigatório",
        });
        console.log("O campo E-mail é obrigatório");
      } else {
        setIsSubmitting(true);
        enviaEmail(
            //email
          );
      }
    } catch (error) {
      setIsSubmitting(false);
      addToast({
        type: "error",
        title: "Erro ao enviar dados",
        description:
          "Ocorreu um erro ao solicitar a recuperação de senha.",
      });
      console.log("Ocorreu um erro ao solicitar a recuperação de senha: "+error);
    }
    
  }

  
  function enviaEmail() {
    const packets = {
      email: email
    };
    api.post('reset-password', packets)
        .then(
              response => {//alert(JSON.stringify(response.data));
                if(response.status === 200){
                  setIsSubmitting(false);
                  addToast({
                    type: "success",
                    title: "Email de recuperação enviado com sucesso",
                  });
                  //console.log("Email de recuperação enviado com sucesso");
                  setEmail('');
                  setModalShow(false);
                }
              }
            )
        .catch(error => {
              setIsSubmitting(false);
              console.log("ERROR STATUS:: ",error.response.status);
              console.log("ERROR MESSAGE: ",error.response.data.message);
              addToast({
                type: "error",
                title: "Falha no envio",
                description: "Verifique se o e-mail foi digitado corretamente e tente novamente."
              });
    
              setModalShow(false);
            
            });
  }

  return (
    <ContainerBg>
      <Container>
        <Content>
          <ContainerReset>
            <form onSubmit={handleSubmit}>
            
                  <Label>Recupere sua senha</Label>
                  <InputText
                    type="text"
                    name="email"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(false);
                    }}
                    //disabled={!isAdmin}
                  />            
                <Button type="submit">
                  {isSubmitting ? <Spinner size="sm" /> : "Enviar"}
                </Button>
                <Link to="/">
                  <p>Voltar para a tela de Login</p>
                </Link>
            </form>
          </ContainerReset>
        </Content>
        <section className="footer">
          <img src={Logo} alt="" />
        </section>
      </Container>
    </ContainerBg>
  );
};

export default ResetPassword;
