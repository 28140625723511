import React, { useState,FormEvent } from "react";
import { useHistory } from "react-router-dom";
//import { cpfMask } from "../../utils";

import { ContainerBg, Container, Content, ContainerReset, InputText } from "./styles";
import Button from "../../components/Button";

import Logo from "../../assets/logo_las.svg";

import api from "../../services/api";

//import Footer from "../../components/Footer";
import Label from "../../components/Label";
import { Spinner } from "reactstrap";

import { useToast } from "../../hooks/ToastContext";


const ChangePassword: React.FC = () =>  {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [email, setEmail] = useState("");
  //const [cpf, setCPF] = useState("");
  const [senha, setSenha] = useState("");
  const [senha_confirm, setSenhaConfirm] = useState("");
  const history = useHistory();
  // eslint-disable-next-line
  const [error, setError] = useState(false);

  //const enviaEmail = teste(email);
  const { addToast } = useToast();
  // eslint-disable-next-line
  const [modalShow, setModalShow] = useState<boolean>(false);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      if (senha === "" || senha_confirm === "") {
        addToast({
          type: "error",
          title: "Campos obrigatórios",
          description: "Os campos de senha são obrigatórios",
        });
      }
      else if (senha !== senha_confirm) {
        addToast({
          type: "error",
          title: "A Senha e a Confirmação de Senha devem ser iguais.",
        });
      }else {
        setIsSubmitting(true);
        alteraSenha();

        //setIsSubmitting(false);

        /*
        addToast({
          type: "success",
          title: "Email de recuperação enviado!",
          // description: 'Ocorreu um erro ao fazer o login, verifique as credenciais.'
        });
        */
      }
    } catch (error) {
      setIsSubmitting(false);
      addToast({
        type: "error",
        title: "Erro ao enviar dados",
        description:
          "Ocorreu um erro ao solicitar a recuperação de senha, verifique se o e-mail está correto.",
      });
    }
  }

  function alteraSenha() {
    setEmail(window.location.search.split("=")[2]);
    let tokenTemp = window.location.search.split("=")[1];
    let token = tokenTemp.split("&")[0];
    //console.log("TOKEN::"+token);
    const packets = {
      email: email,
      passwordToken: token,
      password: senha,
      password_confirmation: senha_confirm
    };
    console.log(token);
    api.post('change-password', packets)
        .then(
              response => {
                //console.log(JSON.stringify(response.data));
                //console.log(JSON.stringify(response.status));
                if(response.status === 201){
                  setIsSubmitting(false);
                  addToast({
                    type: "success",
                    title: "Senha alterada com sucesso",
                  });
                  //console.log("RESPONSE:: "+JSON.stringify(response.data));
                  setModalShow(false);
                  history.push("/");
                }
              }
            )
        .catch(error => {
              setIsSubmitting(false);
              //console.log("ERROR respon:: ",error.response.data.error);
              //console.log("ERROR STATUS:: ",error.response.status);
              //console.log("ERROR MESSAGE: ",error.response.data.message);
              if(error.response.data.error==="Either your email or token is wrong."){
                //console.log("Email ou Token incorreto, Email digitado incorretamente ou Token expirado")
                addToast({
                  type: "error",
                  title: "Email ou Token incorreto",
                  description: "Email digitado incorretamente ou Token expirado."
                });
                setModalShow(false);
              }else{
                addToast({
                  type: "error",
                  title: "Falha no envio",
                  description: "Verifique se as informações foram digitadas corretamente e tente novamente."
                });
                setModalShow(false);
              }
            });
  }

  return (
    <ContainerBg>
      <Container>
        <Content>
          <ContainerReset>
            <form onSubmit={handleSubmit}>
            
                  <Label>Altere sua senha</Label>

                  <InputText
                    type="text"
                    name="email"
                    placeholder="Digite seu e-mail"
                    value={window.location.search.split("=")[2]}
                    /*value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(false);
                    }}*/
                    //disabled={!isAdmin}
                  />

                  {/*<InputText
                    type="text"
                    name="cpf"
                    placeholder="Digite seu CPF"
                    value={cpf}
                    onChange={(e) => {
                      setCPF(cpfMask(e.target.value));
                      setError(false);
                    }}
                    //disabled={!isAdmin}
                  />*/}   

                  <InputText
                    type="password"
                    name="senha"
                    placeholder="Digite sua nova senha"
                    value={senha}
                    onChange={(e) => {
                      setSenha(e.target.value);
                      setError(false);
                    }}
                    //disabled={!isAdmin}
                  />   

                  <InputText
                    type="password"
                    name="senha_confirm"
                    placeholder="Confirme sua nova senha"
                    value={senha_confirm}
                    onChange={(e) => {
                      setSenhaConfirm(e.target.value);
                      setError(false);
                    }}
                    //disabled={!isAdmin}
                  />   

                  {/*<InputText
                    type="text"
                    name="token"
                    hidden
                    placeholder="Placeholder Token Teste"
                    value={window.location.search.split("=")[1]}
                    onChange={(e) => {
                      setToken(e.target.value);
                      setError(false);
                    }}
                    //disabled={!isAdmin}
                  />*/}                            
              
                <Button type="submit">
                  {isSubmitting ? <Spinner size="sm" /> : "Enviar"}
                </Button>
          
            </form>
          </ContainerReset>
        </Content>
        <section className="footer">
          <img src={Logo} alt="" />
        </section>
      </Container>
    </ContainerBg>
  );
};

export default ChangePassword;
