// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import * as S from "./styles";

import Header from "../../components/Header";
// eslint-disable-next-line
import Footer from "../../components/Footer";
// eslint-disable-next-line
import { BlueMain, GreenMain } from "../../styles/colors";

import { useAuth } from "../../hooks/AuthContext";
import api from "../../services/api";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

interface CourseProps {
  id: string;
  name: string;
  description: string;
  image: string;
  percent_watched: string;
  total_lessons_watched: number;
}

export default function Dashboard() {
  const [courses, setCourses] = useState<CourseProps[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    user.id_permission === 2 &&
      api
        .get(`v1/get-courses-user/${user.id}`)
        .then(({ data }) => setCourses(data.courses));

    user.id_permission === 1 &&
      api
        .get(`v1/get-courses-user/${user.id}`)
        .then(({ data }) => setCourses(data.courses));
  }, [user.id, user.id_permission]);

  return (
    <S.Container>
      <Header />
      <S.WrapperSlide>
        {courses.length > 4 ? (
          <Slider {...settings}>
            {courses.map((course) => (
              <S.CourseSlide
                key={course.id}
                to={`/course/${String(course.id)}`}
              >
                <S.NameCourse>{course.name}</S.NameCourse>
                {course.percent_watched === "100%" ? (
                  <S.Icon />
                ) : (
                  <div style={{ width: 15, height: 15 }}>
                    <CircularProgressbar
                      value={40}
                      strokeWidth={13}
                      styles={{
                        path: {
                          stroke: BlueMain,
                        },
                        trail: {
                          stroke: "#07070c",
                        },
                      }}
                    />
                  </div>
                )}
              </S.CourseSlide>
            ))}
          </Slider>
        ) : (
          <S.WrapperButtons>
            {courses.map((course) => (
              <S.CourseSlide
                key={course.id}
                to={`/course/${String(course.id)}`}
              >
                <S.NameCourse>{course.name}</S.NameCourse>
                {course.percent_watched === "100%" ? (
                  <S.Icon />
                ) : (
                  <S.WrapperProgress>
                    <CircularProgressbar
                      value={Number(course.percent_watched)}
                      strokeWidth={13}
                      styles={{
                        path: {
                          stroke: BlueMain,
                        },
                        trail: {
                          stroke: "#07070c",
                        },
                      }}
                    />
                  </S.WrapperProgress>
                )}
              </S.CourseSlide>
            ))}
          </S.WrapperButtons>
        )}
      </S.WrapperSlide>

      <S.Content>
        <S.Columns>
          <div className="title-content">
            <h1>Seja bem vindo</h1>
            <p>
              Simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500
            </p>
          </div>
        </S.Columns>

        <S.Columns>
          <iframe
            src="https://player.vimeo.com/video/516257102?autoplay=1&loop=1&autopause=0"
            title="This is a unique title"
            width="1000"
            height="560"
            allow="autoplay; fullscreen; picture-in-picture"
            frameBorder="none"
            allowFullScreen
          ></iframe>
        </S.Columns>
      </S.Content>
    </S.Container>
  );
}
