import React from "react";

import { Modal, Spinner } from "reactstrap";
import { HiOutlineExclamation } from "react-icons/hi";
import { GreenMain } from "../../styles/colors";
import * as S from "./styles";

interface ModalProps {
  readonly toggle: () => void;
  readonly modal: boolean;
  readonly handleDemoveCourse: (arg1: number) => void;
  readonly id: number;
  readonly isLoadingRemove: boolean;
}
const ModalExclueCourse: React.FC<ModalProps> = ({
  toggle,
  modal,
  handleDemoveCourse,
  id,
  isLoadingRemove,
}) => {
  return (
    <S.ModalDelete>
      <Modal
        className="modal-delete"
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <S.ContentModal>
          <h1>Deseja excluir o curso ?</h1>
          <p>
            <HiOutlineExclamation size={22} color={GreenMain} />É importante
            lembrar que, após a confirmação o curso será removido da plataforma
          </p>
          <S.GroupButton>
            <S.Button onClick={toggle}>Cancelar</S.Button>
            <S.Button delete onClick={() => handleDemoveCourse(id)}>
              {isLoadingRemove ? <Spinner size="sm" /> : "Excluir"}
            </S.Button>
          </S.GroupButton>
        </S.ContentModal>
      </Modal>
    </S.ModalDelete>
  );
};

export default ModalExclueCourse;
