import React, { useState } from "react";

import { Link } from "react-router-dom";

import { useAuth } from "../../hooks/AuthContext";
import {
  HiOutlineUsers,
  HiOutlineHome,
  HiOutlinePresentationChartLine,
  HiOutlineIdentification,
  HiOutlineLogout,
  HiOutlineHand,
  HiOutlineOfficeBuilding,
} from "react-icons/hi";
import { BlueMain } from "../../styles/colors";
import * as S from "./styles";

import logo from "../../assets/logo_las.svg";

const Header: React.FC = () => {
  const { user, signOut } = useAuth();

  const [visible, setVisible] = useState<boolean>(false);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  return (
    <S.Container>
      <S.ContentHeader>
        <S.Logo>
          <img src={logo} alt="" />
        </S.Logo>

        <S.ContentMenu>
          <S.ButtonMenu onClick={handleToggleVisible}>
            <S.IconMenu />
            MENU
          </S.ButtonMenu>

          <S.MenuDrop visible={visible}>
            <S.Menu>
              <nav>
                {user.id_permission === 1 ? (
                  <>
                    <Link to="/dashboard">
                      <HiOutlineHome size={22} color={BlueMain} />
                      <span>Início</span>
                    </Link>
                    <Link to="/departments">
                      <HiOutlineOfficeBuilding size={22} color={BlueMain} />
                      <span>Departamentos</span>
                    </Link>
                    <Link to="/users">
                      <HiOutlineUsers size={22} color={BlueMain} />
                      <span>Usuários</span>
                    </Link>
                    <Link to="/courses">
                      <HiOutlinePresentationChartLine
                        size={22}
                        color={BlueMain}
                      />
                      <span>Cursos</span>
                    </Link>
                    <Link to={`/profile/${user.id}`}>
                      <HiOutlineIdentification size={22} color={BlueMain} />
                      <span>Perfil</span>
                    </Link>
                    <Link className="help" to="/help">
                      <HiOutlineHand size={22} color={BlueMain} />{" "}
                      <span>Ajuda</span>
                    </Link>
                    <Link to="" onClick={signOut}>
                      <HiOutlineLogout size={22} color={BlueMain} />
                      <span>Sair</span>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/dashboard">
                      <HiOutlineHome size={22} color={BlueMain} />
                      <span>Início</span>
                    </Link>
                    <Link to="/courses">
                      <HiOutlinePresentationChartLine
                        size={22}
                        color={BlueMain}
                      />
                      <span>Cursos</span>
                    </Link>
                    <Link to={`/profile/${user.id}`}>
                      <HiOutlineIdentification size={22} color={BlueMain} />
                      <span>Perfil</span>
                    </Link>
                    <Link className="help" to="/help">
                      <HiOutlineHand size={22} color={BlueMain} />
                      <span>Ajuda</span>
                    </Link>
                    <Link to="" onClick={signOut}>
                      <HiOutlineLogout size={22} color={BlueMain} />
                      <span>Sair</span>
                    </Link>
                  </>
                )}
              </nav>
            </S.Menu>
          </S.MenuDrop>
        </S.ContentMenu>
      </S.ContentHeader>
    </S.Container>
  );
};

export default Header;
