import styled from "styled-components";

export const Container = styled.div`
    background:#191c22;
    

    p {
        font-weight:500 !important;
        margin-bottom:0px;
    }

    .header-quiz {
        font-size:2rem;
    }

    .brn-update-quiz {
        justify-content: flex-end;
        display: flex;
    }

    .cardFormNewQuestion {
        background: rgb(255 255 255 / 3%);
        border-radius: 5px;
        box-shadow: 0px 0px 8px 2px #00000026;
        position: relative;
    }
  
    .cardQuestionAdd {
        background: rgb(255 255 255 / 13%);
        border-radius: 5px;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        height: 130px;
        padding:5px;
        line-height:20px;
        text-align: center;
    }

    .cardQuestion {
        background: rgb(255 255 255 / 13%);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        height: 130px;
        padding:10px;
        line-height:20px;
        
        .btn-card {
            height: 25px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .closeButton {
        position: absolute;
        right: 50px;
        top: 15px;
        cursor:pointer;
    }
    
`