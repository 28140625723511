import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

import api from "../../services/api";
import { useToast } from "../../hooks/ToastContext";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Label from "../../components/Label";
import { Spinner } from "reactstrap";
import { cpfMask, phoneMask } from "../../utils";

interface DataCourseProps {
  id_course: number;
  course_name: string;
}

interface DataPermissionProps {
  id: number;
  name: string;
}

interface IDepartments {
  id: number;
  name: string;
}

const RegisterUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");

  const [departments, setDepartments] = useState<IDepartments[]>([]);
  const [permission, setPermission] = useState<number>(0);
  const [dataCourse, setDataCourse] = useState<DataCourseProps[]>([]);
  const [dataPermission, setDataPermission] = useState<DataPermissionProps[]>(
    []
  );
  const [showOptionsDepartment, setShowOptionsDepartment] = useState<boolean>(
    false
  );

  const [departmentsSelected, setDepartmentSelected] = useState<IDepartments[]>(
    []
  );

  const [showOptionsCourses, setShowOptionsCourses] = useState<boolean>(false);

  const [coursesSelected, setCoursesSelected] = useState<DataCourseProps[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addToast } = useToast();
  const selectDepartment = useRef(null);
  const selectCourses = useRef(null);

  useEffect(() => {
    api.get("v1/departament").then((response) => {
      setDepartments(response.data.data);
    });
  }, []);

  useEffect(() => {
    const departmentsIds: number[] = [];
    departmentsSelected.map((department) => {
      departmentsIds.push(department.id);
      return console.log("---")
    });
    if (departmentsIds.length >= 1) {
      api
        .get(`v1/get-courses-departament/${departmentsIds}`)
        .then((response) => {
          setDataCourse(response.data);
        });
    }
  }, [departmentsSelected]);

  useEffect(() => {
    async function getPermissions() {
      const response = await api.get("v1/permission");
      setDataPermission(response.data.data);
    }

    getPermissions();
  }, []);

  const history = useHistory();

  async function handleSubmit() {
    setIsSubmitting(true);
    const departmentsIds: number[] = [];

    departmentsSelected.map((department) => {
      departmentsIds.push(department.id);
      return console.log("---")
    });

    const coursesIds: number[] = [];

    coursesSelected.map((course) => {
      coursesIds.push(course.id_course);
      return console.log("---")
    });

    const body = {
      name: name,
      id_permission: permission,
      id_departament: departmentsIds.length >= 1 && departmentsIds,
      id_course: coursesIds.length >= 1 && coursesIds,
      cpf: cpf,
      email: email,
      phone: phone,
      password: password,
    };

    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      cpf === "" ||
      password === "" ||
      departmentsSelected.length === 0 ||
      coursesSelected.length === 0 ||
      permission === 0
    ) {
      setIsSubmitting(false);
      addToast({
        type: "error",
        title: "Campos obrigatórios",
        description: "Todos os campos são obrigatórios",
      });
    } else {
      api
        .post("v1/user", body)
        .then((response) => {
          if (response.status === 201) {
            addToast({
              type: "success",
              title: "Cadastro realizado com sucesso",
            });

            setIsSubmitting(false);

            history.push("/users");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error.response.data.errors && error.response.data.errors.email) {
            if (
              error.response.data.errors.email[0] ===
              "The email has already been taken."
            ) {
              addToast({
                type: "info",
                title: "E-mail já cadastrado",
                description: "Esse e-mail já está em uso",
              });
            }
          }

          if (error.response.data.errors && error.response.data.errors.cpf) {
            if (error.response.data.errors.cpf[0] === "CPF inválido") {
              return addToast({
                type: "info",
                title: "CPF inválido",
                description: "É necessário informar um CPF válido",
              });
            }

            if (
              error.response.data.errors.cpf[0] ===
              "The cpf has already been taken."
            ) {
              addToast({
                type: "info",
                title: "CPF já cadastrado",
                description: "Esse CPF já está em uso",
              });
            }
          }
        });
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showOptionsDepartment]);

  function handleClickOutside(event: any) {
    if (
      selectDepartment?.current &&
      // @ts-ignore
      !selectDepartment?.current?.contains(event.target)
    ) {
      setShowOptionsDepartment(false);
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousedown", handleClickOutsideCourses);

      return () => {
        window.removeEventListener("mousedown", handleClickOutsideCourses);
      };
    }
  }, [showOptionsCourses]);

  function handleClickOutsideCourses(event: any) {
    if (
      selectCourses?.current &&
      // @ts-ignore
      !selectCourses?.current?.contains(event.target)
    ) {
      setShowOptionsCourses(false);
    }
  }

  const handleAddDepartment = (departmentAdd: IDepartments) => {
    setShowOptionsDepartment(false);
    const auxDepartment = [...departmentsSelected];
    const newDepartmentOptions = departments.filter(
      (dep) => dep.id !== departmentAdd.id
    );

    auxDepartment.push(departmentAdd);

    setDepartmentSelected(auxDepartment);
    setDepartments(newDepartmentOptions);
  };

  const handleRemoveDepartment = (department: IDepartments) => {
    setShowOptionsDepartment(false);
    const auxDepartment = [...departmentsSelected];
    const currentDepartmentOption = [...departments];
    const departmentPosition = auxDepartment.findIndex(
      (dep) => dep.id === department.id
    );
    const departmentDeleted = auxDepartment.find(
      (dep) => dep.id === department.id
    );

    // @ts-ignore
    currentDepartmentOption.push(departmentDeleted);

    auxDepartment.splice(departmentPosition, 1);

    setDepartmentSelected(auxDepartment);
    setDepartments(currentDepartmentOption);
  };

  const handleAddCourse = (courseAdd: DataCourseProps) => {
    setShowOptionsCourses(false);
    const auxCourses = [...coursesSelected];
    const newCoursesOptions = dataCourse.filter(
      (cour) => cour.id_course !== courseAdd.id_course
    );

    auxCourses.push(courseAdd);

    setCoursesSelected(auxCourses);
    setDataCourse(newCoursesOptions);
  };

  const handleRemoveCourse = (course: DataCourseProps) => {
    setShowOptionsCourses(false);
    const auxCourse = [...coursesSelected];
    const currentCourseOption = [...dataCourse];
    const coursePosition = auxCourse.findIndex(
      (cour) => cour.id_course === course.id_course
    );
    const courseDeleted = auxCourse.find(
      (cour) => cour.id_course === course.id_course
    );

    // @ts-ignore
    currentCourseOption.push(courseDeleted);

    auxCourse.splice(coursePosition, 1);

    setCoursesSelected(auxCourse);
    setDataCourse(currentCourseOption);
  };

  return (
    <S.Container>
      <Header />
      <S.Content>
        <S.Columns>
          <form>
            <Label>Nome:</Label>
            <S.InputText
              type="text"
              placeholder="Digite seu nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <Label>E-mail:</Label>
            <S.InputText
              type="text"
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Label>Telefone:</Label>
            <S.InputText
              type="text"
              placeholder="Digite seu telefone"
              value={phone}
              onChange={(e) => setPhone(phoneMask(e.target.value))}
            />

            <Label>Cpf:</Label>
            <S.InputText
              type="text"
              placeholder="Digite seu cpf"
              value={cpf}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
            />

            <Label>Senha:</Label>
            <S.InputText
              type="text"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>
        </S.Columns>
        <S.Columns>
          <Label>Departamento:</Label>

          <div ref={selectDepartment}>
            <S.DepartmentSelect>
              <S.DepartmentSelectButton
                onClick={() =>
                  departments.length >= 1 && setShowOptionsDepartment(true)
                }
              >
                {!departmentsSelected.length && (
                  <span>Selecione o departamento</span>
                )}
                {departmentsSelected.map((department) => (
                  <S.BadgeDepartment key={department.id}>
                    {department.name}
                    <span onClick={() => handleRemoveDepartment(department)} />
                  </S.BadgeDepartment>
                ))}
              </S.DepartmentSelectButton>
              <S.DepartmentOptions show={showOptionsDepartment}>
                {departments.map((dept) => (
                  <span onClick={() => handleAddDepartment(dept)} key={dept.id}>
                    {dept.name}
                  </span>
                ))}
              </S.DepartmentOptions>
            </S.DepartmentSelect>
          </div>

          <S.GroupInput>
            <Label>Nível de acesso:</Label>

            <select onChange={(e) => setPermission(Number(e.target.value))}>
              <option value={Number(0)}>Selecione o nível</option>
              {dataPermission.map((permission) => (
                <option key={permission.id} value={permission.id}>
                  {permission.name}
                </option>
              ))}
            </select>
          </S.GroupInput>

          <S.GroupInput>
            <Label>Curso:</Label>

            <div ref={selectCourses}>
              <S.DepartmentSelect>
                <S.DepartmentSelectButton
                  onClick={() =>
                    dataCourse.length >= 1 && setShowOptionsCourses(true)
                  }
                >
                  {!coursesSelected.length && <span>Selecione o curso</span>}
                  {coursesSelected.map((course) => (
                    <S.BadgeDepartment key={course.id_course}>
                      {course.course_name}
                      <span onClick={() => handleRemoveCourse(course)} />
                    </S.BadgeDepartment>
                  ))}
                </S.DepartmentSelectButton>
                <S.DepartmentOptions show={showOptionsCourses}>
                  {dataCourse.map((course) => (
                    <span
                      onClick={() => handleAddCourse(course)}
                      key={course.id_course}
                    >
                      {course.course_name}
                    </span>
                  ))}
                </S.DepartmentOptions>
              </S.DepartmentSelect>
            </div>
          </S.GroupInput>

          <S.ButtonSubmit onClick={handleSubmit}>
            {isSubmitting ? <Spinner size="sm" /> : "Cadastrar"}
          </S.ButtonSubmit>
        </S.Columns>
      </S.Content>
      <section className="footer">
        <Footer />
      </section>
    </S.Container>
  );
};

export default RegisterUser;
