import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* padding: 3rem 0rem; */
`;

export const ContentFooter = styled.div`
  p {
    font-size: 12px;
  }
`;
