import React, { ButtonHTMLAttributes } from "react";

import { Container, ButtonField } from "./styles";

// interface ButtonProps {
//     children: string,
//     onClick?: any,
//     type?: any
// }

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  danger?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  danger,
  ...rest
}) => {
  return (
    <Container>
      <ButtonField type="button" danger={danger} {...rest}>
        {loading ? "Carregando..." : children}
      </ButtonField>
    </Container>
  );
};

export default Button;
