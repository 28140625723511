//# components
import React, { useState } from "react";

//# Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // eslint-disable-next-line
  Spinner,
  FormGroup,
  Form,
  Input,
  Label,
  // eslint-disable-next-line
  Row,
  // eslint-disable-next-line
  Col,
} from "reactstrap";
import { useToast } from "../../hooks/ToastContext";

//# Components
import { Container } from "./styles";

interface ModalProps {
  readonly toggle: () => void;
  readonly modal: boolean;
  readonly handleCreateQuizz: (arg1: String, arg2: String) => void;
}

const ModalExclueCourse: React.FC<ModalProps> = ({
  toggle,
  modal,
  handleCreateQuizz,
}) => {
  const [state, setState] = useState({
    title: "",
    description: "",
    error: false,
  });
  const { addToast } = useToast();

  const handleSubmit = async () => {
    if (state.title === "" || state.description === "") {
      setState({
        ...state,
        error: true,
      });
      return addToast({
        type: "error",
        title: "Adicione as perguntas antes de criar o quiz",
      });
    }
    await handleCreateQuizz(state.title, state.description);
    setState({
      title: "",
      description: "",
      error: false,
    });
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered={true} size="lg">
        <Container>
          <div>
            <ModalHeader
              charCode=""
              toggle={toggle}
              style={{ borderColor: "#484848c2" }}
            >
              {" "}
              <h1 style={{ fontSize: 20, fontWeight: 600 }}>Criar Quiz</h1>
            </ModalHeader>
            <Form>
              <ModalBody>
                <div className="containerFormModuleAdd p-3">
                  <FormGroup className="input-module">
                    <Label>Título</Label>
                    <Input
                      style={{ color: "#000" }}
                      type="text"
                      name="module"
                      id="module"
                      placeholder="Digite o título do Quiz"
                      value={state.title}
                      onChange={(e) =>
                        setState({ ...state, title: e.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="input-module mt-4">
                    <Label>Descrição</Label>
                    <Input
                      style={{ backgroundColor: "#c4c4c4", color: "#000" }}
                      type="textarea"
                      rows={5.2}
                      name="module"
                      id="module"
                      placeholder="Digite a descrição"
                      value={state.description}
                      onChange={(e) =>
                        setState({ ...state, description: e.target.value })
                      }
                    />
                  </FormGroup>
                </div>
                {state.error && (
                  <div className="alert alert-warning" role="alert">
                    <p>* Todos os campos são obrigatórios</p>
                  </div>
                )}
              </ModalBody>
              <ModalFooter style={{ borderColor: "#484848c2" }}>
                <Button
                  style={{ width: "20%" }}
                  className="btn-primary"
                  onClick={handleSubmit}
                >
                  Criar Quiz
                </Button>{" "}
              </ModalFooter>
            </Form>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default ModalExclueCourse;
