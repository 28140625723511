import React, { createContext, useCallback, useContext, useState } from 'react';
// eslint-disable-next-line
import { uuid } from 'uuidv4';
import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
    id: string,
    type?: 'success' | 'error' | 'info',
    title: string,
    description?: string
};

interface ToastContextData {
    addToast(message: Omit<ToastMessage, 'id'>): void,
    removeToast(id: string): void
};

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
    const [messages, setMessages] = useState<ToastMessage[]>([]);

    const addToast = useCallback(({ type, title, description }: Omit<ToastMessage, 'id'>) => {
    
        //teste random
        const min = 1;
        const max = 100;
        const rand = min + Math.random() * (max - min);
        //console.log("Numero aleatorio: "+rand);
        
        const id = String(Date.now())+rand;//uuid();
        //console.log(id);
        const toast = {
            id,
            type,
            title,
            description
        };

        setMessages((state) => [...state, toast]);
        
    }, []);

    const removeToast = useCallback((id: string) => {
        setMessages(state => state.filter(message => message.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            {<ToastContainer messages={messages} />}
        </ToastContext.Provider>
    );
};

function useToast(): ToastContextData {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return context;
}

export { ToastProvider, useToast };