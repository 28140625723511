//# components
import React, { useState, useEffect } from "react";

//# Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { useToast } from "../../hooks/ToastContext";
import { RiQuestionnaireFill, RiCloseCircleFill } from "react-icons/ri";

//# Styles
import { Container } from "./styles";

//# services
import api from "../../services/api";

interface ModalProps {
  readonly toggle: () => void;
  readonly modal: boolean;
  readonly idQuiz: number;
  readonly handleGetCourseData: () => void;
  readonly module: any;
  readonly modulePosition: any;
  readonly quizPosition: any;
  readonly dataQuiz: any;
}

const ModalExclueCourse: React.FC<ModalProps> = ({
  toggle,
  modal,
  idQuiz,
  handleGetCourseData,
  module,
  modulePosition,
  quizPosition,
  dataQuiz,
}) => {
  const [stateModule, setStateModule] = useState({
    title: "",
    description: "",
    questions: [],
    titleHeader: "",
  });

  useEffect(() => {
    const handleGetData = () => {
      if (!idQuiz) return;
      handleGetQuiz();
      setError(false);
    };
    handleGetData();
    // eslint-disable-next-line
  }, [idQuiz]);

  const [state, setState] = useState({
    title: "",
    description: "",
    error: false,
  });
  const [stateForm, setStateForm] = useState({
    id: "",
    title: "",
    description: "",
    id_quiz: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    option_5: "",
    correct_option: "",
  });
  const [isAdd, setIsAdd] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { addToast } = useToast();
  // eslint-disable-next-line
  const handleSubmit = async () => {
    if (state.title === "" || state.description === "") {
      setState({
        ...state,
        error: true,
      });
      return addToast({
        type: "error",
        title: "Adicione as perguntas antes de criar o quiz",
      });
    }
    //await handleCreateQuizz(state.title,state.description)
    setState({
      title: "",
      description: "",
      error: false,
    });
    toggle();
  };

  const handleUpdateQuiz = async () => {
    setError(false);
    try {
      if (stateModule.title === "" || stateModule.description === "") {
        setState({
          ...state,
          error: true,
        });
        return addToast({
          type: "error",
          title: "Adicione as perguntas antes de criar o quiz",
        });
      }
      const data = {
        title: stateModule.title,
        description: stateModule.description,
        id_module: dataQuiz.id_module,
      };
      const { data: result } = await api.put(`v1/quiz/${dataQuiz.id}}`, data);
      console.log(result, "PUT RESULT ------");

      handleGetQuiz();
    } catch {}
  };

  const handleGetQuiz = async () => {
    try {
      console.log(dataQuiz.id);
      const { data: result } = await api.get(`v1/quiz/${idQuiz}}`);
      console.log(result, "------");
      setStateModule({
        ...stateModule,
        title: result.quiz.title,
        description: result.quiz.description,
        questions: result.questions,
        titleHeader: result.quiz.title,
      });
    } catch {}
  };

  const handleCreateQuestion = async () => {
    try {
      setIsLoading(true);
      if (
        stateForm.title === "" ||
        stateForm.description === "" ||
        stateForm.option_1 === "" ||
        stateForm.option_2 === "" ||
        stateForm.option_3 === "" ||
        stateForm.option_4 === "" ||
        stateForm.option_5 === "" ||
        stateForm.correct_option === ""
      ) {
        return setError(true);
      }

      console.log("adsd");
      const data = {
        title: stateForm.title,
        id_quiz: dataQuiz.id,
        description: stateForm.description,
        option_1: stateForm.option_1,
        option_2: stateForm.option_2,
        option_3: stateForm.option_3,
        option_4: stateForm.option_4,
        option_5: stateForm.option_5,
        correct_option: parseInt(stateForm.correct_option),
      };
      const { data: resultQuestion } = await api.post("v1/question", data);
      console.log(resultQuestion);
      handleUpdateQuiz();
      setIsLoading(false);
      handleCloseCard();
    } catch {
      setIsLoading(false);
      setError(true);
    }
  };

  const handleEditQuestion = async () => {
    try {
      setIsLoading(true);
      if (
        stateForm.title === "" ||
        stateForm.description === "" ||
        stateForm.option_1 === "" ||
        stateForm.option_2 === "" ||
        stateForm.option_3 === "" ||
        stateForm.option_4 === "" ||
        stateForm.option_5 === "" ||
        stateForm.correct_option === ""
      ) {
        return setError(true);
      }

      const data = {
        id: stateForm.id,
        title: stateForm.title,
        id_quiz: dataQuiz.id,
        description: stateForm.description,
        option_1: stateForm.option_1,
        option_2: stateForm.option_2,
        option_3: stateForm.option_3,
        option_4: stateForm.option_4,
        option_5: stateForm.option_5,
        correct_option: parseInt(stateForm.correct_option),
      };
      const { data: resultQuestion } = await api.put(
        `v1/question/${data.id}`,
        data
      );
      console.log(resultQuestion);
      handleUpdateQuiz();
      setIsLoading(false);
      setIsUpdate(false);
      handleCloseCard();
    } catch {
      setIsLoading(false);
      setIsUpdate(false);
      handleCloseCard();
      setError(true);
    }
  };
  const handlesetUpdate = (data: any) => {
    console.log(data);
    setIsUpdate(true);
    setStateForm({
      id: data.id,
      title: data.title,
      description: data.description,
      id_quiz: data.id_quiz,
      option_1: data.option_1,
      option_2: data.option_2,
      option_3: data.option_3,
      option_4: data.option_4,
      option_5: data.option_5,
      correct_option: data.correct_option,
    });
    setIsAdd(true);
  };

  const handleRemoveQuestion = async (id: Number) => {
    try {
      const { data: resultQuestion } = await api.delete(`v1/question/${id}`);
      console.log(resultQuestion);
      handleUpdateQuiz();
    } catch (e) {}
  };

  const handleCloseCard = () => {
    setStateForm({
      id: "",
      title: "",
      description: "",
      id_quiz: "",
      option_1: "",
      option_2: "",
      option_3: "",
      option_4: "",
      option_5: "",
      correct_option: "",
    });
    setIsAdd(false);
    setStateModule({
      title: "",
      description: "",
      questions: [],
      titleHeader: "",
    });
  };

  return (
    <div>
      <Modal
        style={{ maxWidth: 1200 }}
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
      >
        <Container>
          <div>
            <ModalHeader
              charCode=""
              toggle={toggle}
              style={{ borderColor: "#484848c2" }}
            >
              {" "}
              <h1 style={{ fontSize: 20, fontWeight: 600 }}>
                {stateModule.titleHeader}
              </h1>
            </ModalHeader>

            <ModalBody>
              <div className="containerFormModuleAdd p-3">
                <Form>
                  <div className="header-quiz mb-4">
                    <h1>Editar Quiz</h1>
                  </div>
                  <FormGroup className="input-module">
                    <Label>Título</Label>
                    <Input
                      style={{ color: "#000" }}
                      type="text"
                      name="module"
                      id="module"
                      placeholder="Digite o título do Quiz"
                      value={stateModule.title}
                      onChange={(e) =>
                        setStateModule({
                          ...stateModule,
                          title: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="input-module mt-4">
                    <Label>Descrição</Label>
                    <Input
                      style={{ backgroundColor: "#c4c4c4", color: "#000" }}
                      type="textarea"
                      rows={5.2}
                      name="module"
                      id="module"
                      placeholder="Digite a descrição"
                      value={stateModule.description}
                      onChange={(e) =>
                        setStateModule({
                          ...stateModule,
                          description: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="brn-update-quiz">
                    <Button
                      style={{ width: "20%" }}
                      onClick={() => handleUpdateQuiz()}
                      className="btn-primary"
                    >
                      Atualizar Quiz
                    </Button>{" "}
                  </FormGroup>
                </Form>
              </div>
              {state.error && (
                <div className="alert alert-warning" role="alert">
                  <p>* Todos os campos são obrigatórios</p>
                </div>
              )}

              <div className="container-questions px-3">
                <div className="header-quiz mb-4">
                  <h1>Criar e editar questões</h1>
                </div>

                <Row>
                  {isAdd && (
                    <Col md={12}>
                      <Form>
                        <Row>
                          <Col md={12} className="p-5 cardFormNewQuestion">
                            <RiCloseCircleFill
                              className="closeButton"
                              color="#fff"
                              size={30}
                              onClick={() => handleCloseCard()}
                            />
                            <FormGroup className="input-module mt-3">
                              <Label>Título</Label>
                              <Input
                                style={{ color: "#000" }}
                                type="text"
                                name="module"
                                id="module"
                                placeholder="Digite o título da sua Questão"
                                value={stateForm.title}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    title: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Descrição</Label>
                              <Input
                                style={{
                                  color: "#000",
                                  backgroundColor: "#c4c4c4",
                                }}
                                type="textarea"
                                rows={5.2}
                                name="module"
                                id="module"
                                placeholder="Descreve sua questão"
                                value={stateForm.description}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Resposta Correta</Label>
                              <Input
                                type="select"
                                name="select"
                                style={{ backgroundColor: "#c4c4c4" }}
                                value={stateForm.correct_option}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    correct_option: e.target.value,
                                  })
                                }
                              >
                                <option value="">Selecione</option>
                                <option value={1}> Opção 1</option>
                                <option value={2}> Opção 2</option>
                                <option value={3}> Opção 3</option>
                                <option value={4}> Opção 4</option>
                                <option value={5}> Opção 5</option>
                              </Input>
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Opção 1 </Label>
                              <Input
                                style={{ color: "#000" }}
                                type="text"
                                name="module"
                                id="module"
                                placeholder="Digite opção"
                                value={stateForm.option_1}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    option_1: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Opção 2 </Label>
                              <Input
                                style={{ color: "#000" }}
                                type="text"
                                name="module"
                                id="module"
                                placeholder="Digite opção"
                                value={stateForm.option_2}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    option_2: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Opção 3 </Label>
                              <Input
                                style={{ color: "#000" }}
                                type="text"
                                name="module"
                                id="module"
                                placeholder="Digite opção"
                                value={stateForm.option_3}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    option_3: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Opção 4 </Label>
                              <Input
                                style={{ color: "#000" }}
                                type="text"
                                name="module"
                                id="module"
                                placeholder="Digite opção"
                                value={stateForm.option_4}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    option_4: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="input-module">
                              <Label>Opção 5 </Label>
                              <Input
                                style={{ color: "#000" }}
                                type="text"
                                name="module"
                                id="module"
                                placeholder="Digite opção"
                                value={stateForm.option_5}
                                onChange={(e) =>
                                  setStateForm({
                                    ...stateForm,
                                    option_5: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <FormGroup className="brn-update-quiz">
                              {isUpdate ? (
                                <>
                                  <Button
                                    style={{ width: "20%" }}
                                    onClick={() => handleEditQuestion()}
                                    className="btn-primary"
                                  >
                                    {isLoading ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      " Editar Pergunta"
                                    )}
                                  </Button>{" "}
                                </>
                              ) : (
                                <>
                                  <Button
                                    style={{ width: "20%" }}
                                    onClick={() => handleCreateQuestion()}
                                    className="btn-primary"
                                  >
                                    {isLoading ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      " Criar Pergunta"
                                    )}
                                  </Button>{" "}
                                </>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  )}

                  <Col md={3}>
                    <div
                      className="cardQuestionAdd mt-3"
                      onClick={() => setIsAdd(true)}
                    >
                      <div>
                        <RiQuestionnaireFill color="#fff" size={30} />
                      </div>

                      <p className="mt-3">
                        Clique para adicionar uma
                        <br />
                        pergunta ao Quiz
                      </p>
                    </div>
                  </Col>
                  {stateModule.questions?.length ? (
                    <>
                      {stateModule.questions.map((value: any, index) => (
                        <Col md={3}>
                          <div className="cardQuestion mt-3">
                            <div>
                              <p>Título: {value.title}</p>
                            </div>
                            <Button
                              onClick={() => {
                                handlesetUpdate(value);
                              }}
                              className="mt-2 btn-card"
                            >
                              Editar
                            </Button>
                            <Button
                              onClick={() => {
                                handleRemoveQuestion(value.id);
                              }}
                              className="mt-3 btn-card"
                            >
                              Remover
                            </Button>
                          </div>
                        </Col>
                      ))}
                    </>
                  ) : null}
                </Row>
              </div>
            </ModalBody>
            <ModalFooter style={{ borderColor: "#484848c2" }}>
              <Button
                style={{ width: "20%" }}
                onClick={toggle}
                className="btn-primary"
              >
                Finalizar
              </Button>{" "}
            </ModalFooter>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default ModalExclueCourse;
