import styled from "styled-components";
import { BlueMain, RedMain } from "../../styles/colors";

interface ButtonProps {
  danger?: boolean;
}

export const Container = styled.div`
  /* margin-top: 15px; */
`;

export const ButtonField = styled.button<ButtonProps>`
  background: ${(props) => (props.danger ? RedMain : BlueMain)};
  padding: 15px 0px;
  border-radius: 5px;
  border-width: 0;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${(props) => (props.danger ? "#e55050" : "#187ea4")};
  }
`;
