import styled from "styled-components";
import { BlackMain, BlueMain, BlackSecondary } from "../../styles/colors";

export const Help = styled.div`
  height: 100vh;
  padding-top: 140px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${BlackMain};
`;

export const ContentHelp = styled.div`
  height: 100%;
  width: 80%;
  h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    display: block;
  }

  button {
    background: ${BlueMain};
    padding: 15px 20px;
    border-radius: 5px;
    border-width: 0;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    border: none;
    transition: background-color 0.2s;
    min-width: 100px;
    margin-left: auto;
    margin-top: 40px;
    display: block;
    width: 200px;

    &:hover {
      background: #187ea4;
    }
  }
`;

export const TextArea = styled.textarea`
  background: ${BlackSecondary};
  border-radius: 5px;
  font-size: 14px;
  padding: 20px;
  width: 100%;
  /* margin: 7px 0; */
  resize: none;
  color: white;
`;

export const WrapperCourses = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
  overflow-y: auto;

  input {
    height: 30px;
  }
  label {
    margin: 0;
    margin-left: 10px;
    cursor: pointer;
    width: max-content;
  }
`;

export const GroupCheckbox = styled.div`
  margin: 20px 0px;
`;

export const GroupInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    margin-bottom: 10px;
  }
`;
