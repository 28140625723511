import styled from "styled-components";

import Input from "../../components/Input";
import Button from "../../components/Button";
import {
  BlackMain,
  BlackSecondary,
  RedMain,
  BlueMain,
} from "../../styles/colors";

export const Container = styled.div`
  padding-top: 85px;
  .footer {
    padding: 40px 0px;
  }
`;

export const ModalContent = styled.div`
  background: ${BlackMain};
  border-radius: 15px;
  padding: 32px;
`;

export const Content = styled.div`
  max-width: 80%;
  margin: 30px auto 0px;
  border-radius: 5px;

  h1,
  h1 {
    margin: 0px;
  }
`;

export const ContentFilters = styled.div`
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
`;

export const InputSearch = styled(Input)`
  width: 300px;
  height: 45px;
`;

export const ContentList = styled.div`
  width: 100%;
  display: flex;
  color: #000000;
  flex-direction: row;
  background: #191c22;
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 5px;
  color: #ffffff;

  :hover {
    background: #0d0f12;
  }
`;

export const ContentListHeader = styled.div`
  width: 100%;
  display: flex;
  color: #000000;
  flex-direction: row;
  background: #0d0f12;
  padding: 15px 20px;
  border-radius: 5px;
  color: #ffffff;
  align-items: center;
  margin-bottom: 10px;
`;

export const Column = styled.div`
  display: flex;

  h1 {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
  }
`;

export const ColumnId = styled.div`
  width: 5%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnName = styled.div`
  width: 45%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnEmail = styled.div`
  width: 45%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnLevel = styled.div`
  width: 15%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnStatus = styled.div`
  width: 30%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnActions = styled.div`
  width: 10%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ButtonAddDepartment = styled(Button)`
  width: 210px;
  height: 45px;
  margin-left: 20px;
  margin-bottom: 30px;
`;

export const ButtonRegister = styled(Button)`
  background: ${BlueMain};
  padding: 15px 0px;
  border-radius: 5px;
  height: 45px;
  border-width: 0;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  transition: background 0.2s ease-in-out;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #187ea4;
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ButtonClose = styled(Button)`
  background: ${RedMain};
  height: 45px;
  display: block;
  padding: 15px 0px;
  border-radius: 5px;
  border-width: 0;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  transition: background 0.2s ease-in-out;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  display: block;

  &:hover {
    background: #e55050;
  }
`;

export const Form = styled.form``;

export const InputText = styled(Input)`
  height: 60px;
  width: 300px;
`;

export const Label = styled.span`
  font-size: 12px;
  color: #fff;
  margin-bottom: 10px;
`;

export const TitleModal = styled.h1`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const Select = styled.select`
  height: 60px;
  width: 300px;
  background: ${BlackSecondary};
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 12px;
  color: #696c73;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  select,
  input {
    width: 100%;
  }
`;
