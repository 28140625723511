import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useToast } from "../../hooks/ToastContext";
import { useAuth } from "../../hooks/AuthContext";
import api from "../../services/api";
import * as S from "./styles";

interface dataHelpProps {
  message: string;
  subject: string;
  course_id: any;
}

interface CourseProps {
  id: string;
  name: string;
  description: string;
  image: string;
  percent_watched: string;
  total_lessons_watched: number;
}

const initial_data_help: dataHelpProps = {
  message: "",
  subject: "",
  course_id: null,
};

const Help = () => {
  const [dataHelp, setDataHelp] = useState<dataHelpProps | any>(
    initial_data_help
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useToast();
  const [courses, setCourses] = useState<CourseProps[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    api
      .get(`v1/get-courses-user/${user.id}`)
      .then(({ data }) => setCourses(data.courses));
      // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    const dataMessage = {
      id_course: dataHelp.course_id,
      subject: dataHelp.subject,
      message: dataHelp.message,
    };

    setIsSubmitting(true);

    await api
      .post("v1/message", dataMessage)
      .then(({ data }) => {
        addToast({
          type: "success",
          title: "Mensagem enviada com sucesso!",
        });
        setDataHelp(initial_data_help);
      })
      .catch((err) => {
        setIsSubmitting(false);
      });

    setIsSubmitting(false);
  };

  const handleChange = (id: string) => {
    setDataHelp({
      ...dataHelp,
      course_id: id,
    });
  };

  return (
    <S.Help>
      <Header />
      <S.ContentHelp>
        <h1>Selecione abaixo o curso que deseja tirar uma dúvida</h1>

        <S.GroupCheckbox>
          {courses?.map((course) => (
            <S.WrapperCourses key={course.id}>
              <input
                type="radio"
                checked={dataHelp.course_id === course.id}
                name={`${course.name}-checkbox`}
                id={`${course.name}-checkbox`}
                onChange={() => handleChange(course.id)}
              />
              <label htmlFor={`${course.name}-checkbox`}>{course.name}</label>
            </S.WrapperCourses>
          ))}
        </S.GroupCheckbox>

        <S.GroupInput>
          <label>Assunto</label>
          <input
            type="text"
            name="subject"
            placeholder="Assunto"
            onChange={(e) =>
              setDataHelp({ ...dataHelp, subject: e.target.value })
            }
          />
        </S.GroupInput>

        <S.GroupInput>
          <label>Mensagem</label>
          <S.TextArea
            placeholder="Digite uma mensagem"
            name="message"
            rows={5.2}
            value={dataHelp.message}
            onChange={(e) =>
              setDataHelp({ ...dataHelp, message: e.target.value })
            }
          />
        </S.GroupInput>

        <button onClick={handleSubmit}>
          {isSubmitting ? <Spinner size="sm" /> : "Enviar"}
        </button>
      </S.ContentHelp>
      <Footer />
    </S.Help>
  );
};

export default Help;
