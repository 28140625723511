import styled from "styled-components";

import Input from "../../components/Input";
import Button from "../../components/Button";

export const Container = styled.div`
  padding-top: 85px;
`;

export const Content = styled.div`
  max-width: 80%;
  margin: 30px auto;
  padding: 5px 15px;
  border-radius: 5px;

  h1,
  h2 {
    margin: 0px;
  }
`;

export const ContentFilters = styled.div`
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
`;

export const InputSearch = styled(Input)`
  width: 300px;
  height: 45px;
`;

export const ContentList = styled.div`
  width: 100%;
  display: flex;
  color: #000000;
  padding: 8px 0;
  flex-direction: row;
  background: #191c22;
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 5px;

  color: #ffffff;

  :hover {
    background: #0d0f12;
  }
`;

export const ContentListHeader = styled.div`
  width: 100%;
  display: flex;
  color: #000000;
  padding: 8px 0;
  flex-direction: row;
  background: #0d0f12;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 20px;
  border-radius: 5px;
  color: #ffffff;
`;

export const Column = styled.div`
  display: flex;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ColumnId = styled.div`
  width: 5%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnName = styled.div`
  width: 45%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnEmail = styled.div`
  width: 45%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnLevel = styled.div`
  width: 15%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnStatus = styled.div`
  width: 30%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ColumnActions = styled.div`
  width: 10%;

  h1 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ButtonRegister = styled(Button)`
  width: 180px;
  height: 45px;
  margin-left: 20px;
  margin-top: -7px;
`;
